import React, { memo, useCallback } from "react";
import { EcoLabelPopupLabelType } from "../../../../../../../../common-deprecated/types/SettingsType";
import EmissionLabelComponent from "../../../../../../../../common-deprecated/components/eco-labels/EmissionLabelComponent";
import styles from "./EfficiencyClass.module.scss";

type PropsType = {
    label: EcoLabelPopupLabelType;
    hideInfoIcon?: boolean;
};

const EfficiencyClass = (props: PropsType): JSX.Element | null => {
    const onClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    }, []);

    return (
        <span onClick={onClick} className={styles.wrapper}>
            <EmissionLabelComponent {...props} />
        </span>
    );
};

export default memo(EfficiencyClass);
