import {
    CarConfigStep,
    getCarConfigUrl,
} from "../../../../common-deprecated/features/apheleia/utils/carConfigNavigationUtils";
import { getVisualForGradeImage } from "../../../../common-deprecated/settings/utils/ccisSettingUtils";
import { useGradeSelectorSelector } from "../redux/hooks";
import { selectModel } from "../redux/slices/GradeSelectorModelSlice";
import { GradeSelectorStateType } from "../redux/store";
import { BodyTypeType, CarType, EquipmentType, GradeType } from "./types/Model";

export const useVisibleEquipment = (path?: string): EquipmentType[] => {
    const { equipment } = useGradeSelectorSelector(selectModel);
    return equipment.filter(({ groupPath }) => (path ? path === groupPath : true));
};

/**
 * Get image for current grade and bodyType
 *
 * @param grade
 * @param bodyType
 * @param width
 * @param height
 * @param angle
 * @returns
 */
export const useGradeImage = (
    grade: GradeType,
    bodyType: BodyTypeType,
    width: number,
    height: number,
    angle: number,
    type: "jpg" | "png" = "jpg",
    colourCodes?: string,
): ReturnType<typeof getVisualForGradeImage> => {
    const commonSettings = useGradeSelectorSelector((state: GradeSelectorStateType) => state.commonSettings);
    const { id } = useGradeSelectorSelector(selectModel);
    return getVisualForGradeImage(
        commonSettings,
        id,
        grade.id,
        bodyType.id,
        {
            type,
            width,
            height,
            bgColour: type === "png" ? "" : "FFFFFF",
        },
        angle,
        colourCodes,
    );
};

export enum FeatureViewType {
    HighlightsView = "highlights",
    FeaturesView = "details",
}

export enum RetailerFeatureViewType {
    HighlightsView = "highlights",
    FeaturesView = "details",
    SpecsView = "specs",
}

export const useFeatureView = (): FeatureViewType => {
    const currentFilter = useGradeSelectorSelector((state) => state.gradeSelectorFilters.category);

    if (typeof currentFilter !== "undefined") {
        return FeatureViewType.FeaturesView;
    }

    return FeatureViewType.HighlightsView;
};

export const useCarConfigUrl = (
    car: CarType,
    carConfigStep?: CarConfigStep,
    colour?: { colourId: string; colourCode: string },
): string => {
    const commonSettings = useGradeSelectorSelector((state: GradeSelectorStateType) => state.commonSettings);
    const model = useGradeSelectorSelector(selectModel);

    return getCarConfigUrl(
        commonSettings,
        model.id,
        model.code,
        car.CarID,
        carConfigStep || CarConfigStep.Configure,
        colour,
    );
};
