import { useDispatch } from "react-redux";
import React, { forwardRef, memo, useCallback } from "react";
import clsx from "clsx";
import { GrCode } from "../../../../../../../common-deprecated/constants";
import {
    getColourCodeById,
    getColourPrice,
    getEta,
    getGRLogo,
    getMotorizationData,
    getName,
} from "../../../../utils/gradeUtils";
import { useCarConfigUrl, useGradeImage } from "../../../../utils/gradeHooks";
import { GradeSelectorMemoLabel } from "../../../../utils/labels";
import GRLogo from "../../../../../../../../assets/toyota/svg/GR/TME-GR.svg";
import GRLogoSport from "../../../../../../../../assets/toyota/svg/GR/TME-GR-sport-black.svg";
import { GradeBodyTypeType } from "../../../../utils/types/Model";
import Price from "./price/Price";
import Text from "../../../../../shared-components/css-modules/toyota/atoms/Text";
import Button from "../../../../../shared-components/css-modules/toyota/atoms/Button";
import styles from "./Item.module.scss";
import { trackSelectGrade } from "../../../../utils/tracking";
import a11y from "../../../../../shared-components/css-modules/toyota/utils/Accessibility.module.scss";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import { getThemeClassname } from "../../../../../../../common-deprecated/utils";
import { setColour } from "../../../../redux/slices/GradeSelectorModelSlice";
import { EcoItems } from "./EcoItems";
import { GradeSelectorDispatchType } from "../../../../redux/store";
import { showModal } from "../../../../../../../common-deprecated/redux/actions/ModalActions";
import { MODAL_GRADE_SELECTOR_COMPARE } from "../../../../utils/modalConstants";
import Icon from "../../../../../shared-components/css-modules/toyota/atoms/Icon";
import Swatch from "../../../../../shared-components/css-modules/toyota/atoms/Swatch";
import { getUniqueGradeBodyTypeId } from "../../../../../../../common-deprecated/features/apheleia/utils/aphUtils";
import InfoSection from "./info-section/InfoSection";
import { useFilteredGradeBodyTypes } from "../../../../utils/filterHooks";
import Incentive from "../../../../../shared-components/css-modules/toyota/molecules/Incentive";

type ItemProps = {
    gradeBodyType: GradeBodyTypeType;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(({ gradeBodyType }, ref) => {
    const dispatch = useDispatch<GradeSelectorDispatchType>();

    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);

    const id = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.id);
    const gradeBodyTypes = useFilteredGradeBodyTypes().length;

    const { hasMultipleBodyTypes, bodyType, grade, engines } = gradeBodyType;
    const name = getName(gradeBodyType);
    const eta = getEta(gradeBodyType);
    const grLogo = getGRLogo(gradeBodyType);
    const colourCode = getColourCodeById(gradeBodyType);
    const imageBase = useGradeImage(grade, bodyType, 331, 131, 3, undefined, colourCode).src;
    const imageBase2x = useGradeImage(grade, bodyType, 331 * 2, 131 * 2, 3, undefined, colourCode).src;

    const { wheeldrive } = getMotorizationData(gradeBodyType);
    const { price, car } = wheeldrive;
    const colourPrice = getColourPrice(gradeBodyType.selectedColourId, wheeldrive);

    const carConfigUrl = useCarConfigUrl(
        car,
        undefined,
        gradeBodyType?.selectedColourId
            ? {
                  colourId: gradeBodyType.selectedColourId,
                  colourCode,
              }
            : undefined,
    );

    const uniqueBodyTypeId = getUniqueGradeBodyTypeId(grade.id, gradeBodyType.bodyType.id, gradeBodyType.submodel?.id);

    const MAX_COMPARABLE_ITEMS = 2;

    const onCTAClick = useCallback(() => {
        trackSelectGrade(gradeBodyType.grade.name);
    }, [gradeBodyType.grade.name]);

    return (
        <article className={clsx(styles.wrapper, getThemeClassname(commonSettings.query, id))} ref={ref}>
            <section className={styles.section}>
                <header className={styles.header}>
                    <div>
                        <div className={styles["pre-title"]}>
                            <EcoItems modelId={id} engines={engines} limit={3} disable={{ efficiencyLabel: true }} />

                            {gradeBodyType.bestSellerLabel && (
                                <Incentive label={gradeBodyType.bestSellerLabel} variant="best-seller" />
                            )}
                        </div>
                        <div className={styles.title}>
                            <Text tag="h3" variant="card-subheader-semibold">
                                {name}
                            </Text>
                            <div className={styles.grlogo} aria-hidden="true">
                                {grLogo ? grLogo === GrCode.Gr ? <GRLogo /> : <GRLogoSport /> : null}
                            </div>
                        </div>

                        {hasMultipleBodyTypes && (
                            <Text tag="h4" variant="lead">
                                {bodyType.name}
                            </Text>
                        )}
                    </div>
                </header>
            </section>

            {/* Image */}
            <section className={clsx(styles.section, styles["image-section"])}>
                <a href={carConfigUrl} onClick={onCTAClick}>
                    <figure className={styles.image}>
                        <img
                            src={imageBase}
                            srcSet={`${imageBase} 1x, ${imageBase2x} 2x`}
                            width="331"
                            height="131"
                            loading="lazy"
                            alt={name}
                        />
                    </figure>
                </a>
            </section>

            {/* Colors */}
            <section className={styles.section}>
                <div className={styles.colors}>
                    {wheeldrive.colors.map((colour, index) => (
                        <Swatch
                            key={colour.id}
                            className={styles.swatch}
                            image={colour.asset}
                            fill={colour.rgb}
                            active={
                                (!gradeBodyType.selectedColourId && index === 0) ||
                                colour.id === gradeBodyType.selectedColourId
                            }
                            onClick={() =>
                                dispatch(
                                    setColour({
                                        uniqueBodyTypeId,
                                        colourId: colour.id,
                                    }),
                                )
                            }
                        />
                    ))}
                </div>
            </section>

            {/* Price & ETA */}
            <section className={styles.section}>
                <div className={styles.price}>
                    <Price car={car} price={price} colourPrice={colourPrice} showPromotionsNumbers column />
                </div>
                {eta !== null && (
                    <div className={styles.eta}>
                        <GradeSelectorMemoLabel
                            label="eta"
                            params={{
                                num: eta.toString(),
                            }}
                        />
                    </div>
                )}
            </section>

            {/* CTA's */}
            <section className={styles.section}>
                <div>
                    <Button
                        type="a"
                        href={carConfigUrl}
                        onClick={onCTAClick}
                        variant="btn-default"
                        sizing="full-width"
                        className={styles.button}
                    >
                        <GradeSelectorMemoLabel label="gradeCtaButton" />
                        <span className={a11y["sr-only"]}>{name}</span>
                    </Button>
                    {gradeBodyTypes > MAX_COMPARABLE_ITEMS && (
                        <Button
                            type="button"
                            onClick={() =>
                                dispatch(
                                    showModal({
                                        type: MODAL_GRADE_SELECTOR_COMPARE,
                                        gradeId: gradeBodyType.grade.id,
                                        submodelId: gradeBodyType.submodel?.id,
                                        colourId: gradeBodyType.selectedColourId,
                                        motorization: gradeBodyType.selectedMotorization,
                                    }),
                                )
                            }
                            variant="btn-secondary"
                            sizing="full-width"
                            className={styles.button}
                        >
                            <GradeSelectorMemoLabel label="compare" />
                            <Icon variant="compare" sizing="md" />
                        </Button>
                    )}
                </div>
            </section>

            <InfoSection gradeBodyType={gradeBodyType} />
        </article>
    );
});

export default memo(Item);
