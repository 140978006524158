import { createAction, createReducer } from "@reduxjs/toolkit";
import { gradeSelectorLabels, GradeSelectorLabelType } from "../../utils/labels";

export type GradeSelectorSettingsType = {
    initialized: boolean;
    labels: Record<GradeSelectorLabelType, string>;
    showETA: boolean;
};

export const initialStateGradeSelectorSettings: GradeSelectorSettingsType = {
    initialized: false,
    labels: { ...gradeSelectorLabels },
    showETA: false,
};

export const initGradeSelectorSettings = createAction<GradeSelectorSettingsType, "gradeSelectorSettings/init">(
    "gradeSelectorSettings/init",
);

export type GradeSelectorSettingsActionTypes = ReturnType<typeof initGradeSelectorSettings>;

const GradeSelectorSettingsReducer = createReducer(initialStateGradeSelectorSettings, (builder) => {
    builder.addCase(initGradeSelectorSettings, (state, action) => {
        return {
            ...state,
            ...action.payload,
            initialized: true,
        };
    });
});

export default GradeSelectorSettingsReducer;
