import styled from "styled-components";
import SvgCart from "../../../../svg/toyota/aem/basket.svg";
import SvgCartLeasing from "../../../../svg/toyota/aem/cart-leasing.svg";
import SvgException from "../../../../svg/toyota/aem/exception.svg";
import SvgPhone from "../../../../svg/toyota/aem/phone.svg";
import SvgTag from "../../../../svg/toyota/aem/tag.svg";
import SvgPlusCircle from "../../../../svg/toyota/aem/plus-circle.svg";
import SvgHeart from "../../../../svg/toyota/aem/heart.svg";
import SvgHeartFilled from "../../../../svg/toyota/aem/heart-filled.svg";
import SvgDelivery from "../../../../svg/toyota/aem/delivery.svg";
import SvgBuy from "../../../../svg/toyota/cart-v2.svg";
import SvgStar from "../../../../svg/toyota/aem/star.svg";
import SvgStarFilled from "../../../../svg/toyota/aem/star-filled.svg";
import SvgCheckmark from "../../../../svg/toyota/aem/checkmark.svg";
import SvgClose from "../../../../svg/toyota/aem/close.svg";
import SvgCarExchange from "../../../../svg/toyota/aem/car-exchange.svg";
import SvgArrowRight from "../../../../svg/toyota/aem/arrow-right.svg";
import SvgChevronLeft from "../../../../svg/toyota/aem/chevron-left.svg";
import SvgChevronDown from "../../../../svg/toyota/aem/chevron-down.svg";
import SvgGearbox from "../../../../svg/toyota/aem/gearbox.svg";
import SvgTradeIn from "../../../../svg/toyota/aem/trade-in.svg";
import SvgCartNegative from "../../../../svg/toyota/aem/cart-negative.svg";
import SvgCalculator from "../../../../svg/toyota/aem/calculator.svg";
import SvgChevronRight from "../../../../svg/toyota/aem/chevron-right.svg";
import SvgSort from "../../../../svg/toyota/aem/sort.svg";
import SvgControls from "../../../../svg/toyota/aem/controls.svg";
import SvgMarker from "../../../../svg/toyota/aem/marker.svg";
import SvgLocation from "../../../../svg/toyota/aem/location.svg";
import SvgFullscreen from "../../../../svg/toyota/aem/fullscreen.svg";
import SvgCarSimilarCars from "../../../../svg/toyota/aem/car-similar-cars.svg";
import SvgEdit from "../../../../svg/toyota/aem/edit.svg";
import SvgPlus from "../../../../svg/toyota/aem/plus.svg";
import SvgInfo from "../../../../svg/toyota/aem/info.svg";
import SvgProfile from "../../../../svg/toyota/aem/profile.svg";
import SvgApproved from "../../../../svg/toyota/aem/approved.svg";
import SvgNonApproved from "../../../../svg/toyota/aem/non-approved.svg";
import SvgSearch from "../../../../svg/toyota/aem/search.svg";
import SvgFilters from "../../../../svg/toyota/aem/filters.svg";

const iconVariants = {
    customerCentric: "Customer_Centric",
    profile: "Profile",
    satisfiedCustomer: "Satisfied_Customer",
    userNotification: "User_Notification",
    spin: "Spin",
    charger: "Charger",
    electricStation: "Electric_Station",
    findDealer: "Find_Dealer",
    gas: "Gas",
    globe: "Globe",
    highway: "Highway",
    language: "Language",
    location: "Location",
    map: "Map",
    mapDigital: "Map_Digital",
    noCharger: "No_Charger",
    route: "Route",
    selfCharging: "Self_Charging",
    calendar: "Calendar",
    extendedHours: "Extended_Hours",
    fast: "Fast",
    expressService: "Express_Service",
    fastLaneForSpecificServices: "Fast_Lane_For_Specific_Services",
    freeTechCheck: "Free_Tech_Check",
    time: "Time",
    timeDotted: "Time_Dotted",
    callCenter: "Call_Center",
    newsletterEmail: "Newsletter_Email",
    print: "Print",
    smartphone: "Smartphone",
    website: "Website",
    wifi: "Wifi",
    commercialInsurance: "Commercial_Insurance",
    financingAndInsurance: "Financing_and_Insurance",
    insurance: "Insurance",
    insuranceCalculator: "Insurance_Calculator",
    insuranceCustomerPortal: "Insurance_Customer_Portal",
    lock: "Lock",
    motorInsurance: "Motor_Insurance",
    check: "Check",
    compare: "Compare",
    compareLines: "Compare_Lines",
    controls: "Controls",
    customize: "Customize",
    customizeBlack: "Customize_Black",
    externalLink: "External_Link",
    flexible: "Flexible",
    fullscreen: "Fullscreen",
    hybridHealthCheck: "Hybrid_Health_Check",
    noSound: "No_Sound",
    player: "Player",
    search: "Search",
    sort: "Sort",
    sound: "Sound",
    trade: "Trade",
    trash: "Trash",
    viewOff: "View_Off",
    viewOn: "View_On",
    arrowDown: "Arrow_Down",
    arrowLeft: "Arrow_Left",
    arrowRight: "Arrow_Right",
    arrowUp: "Arrow_Up",
    chevronDown: "Chevron_Down",
    chevronLeft: "Chevron_Left",
    chevronRight: "Chevron_Right",
    chevronUp: "Chevron_Up",
    close: "Close",
    explore: "Explore",
    menu: "Menu",
    minus: "Minus",
    plus: "Plus",
    support: "Support",
    courtesyVan: "Courtesy_Van",
    customerLoyalty: "Customer_Loyalty",
    easy: "Easy",
    hand: "Hand",
    like: "Like",
    qualityAssurance: "Quality_Assurance",
    safeDriving: "Safe_Driving",
    card: "Card",
    coins: "Coins",
    excessBonus: "Excess_Bonus",
    finance: "Finance",
    money: "Money",
    moneyHand: "Money_Hand",
    piggyBank: "Piggy_Bank",
    saveMoney: "Save_Money",
    wallet: "Wallet",
    assessment: "Assessment",
    conventionalWarranty: "Conventional_Warranty",
    download: "Download",
    form: "Form",
    hybridWarranty: "Hybrid_Warranty",
    image: "Image",
    invoice: "Invoice",
    jpg: "JPG",
    link: "Link",
    manual: "Manual",
    mp4: "MP4",
    pdf: "PDF",
    pointCheck: "Point_Check",
    quality: "Quality",
    readyToGo: "Ready_To_Go",
    reserve: "Reserve",
    star: "Star",
    tyreMatch: "Tyre_Match",
    upload: "Upload",
    video: "Video",
    cloud: "Cloud",
    cloudComplete: "Cloud_Complete",
    cloudRain: "Cloud_Rain",
    co2: "CO2",
    environmental: "Environmental",
    greenPromise: "Green_Promise",
    lightning: "Lightning",
    mountainOffice: "Mountain_Office",
    tree: "Tree",
    basket: "Basket",
    shoppingBag: "Shopping_Bag",
    shoppingCart: "Shopping_Cart",
    visitsDealership: "Visits_Dealership",
    chat: "Chat",
    chatbox: "Chatbox",
    personalizedFeedback: "Personalized_Feedback",
    anticipation: "Anticipation",
    elegance: "Elegance",
    h: "H",
    myT: "MyT",
    simplicity: "Simplicity",
    bell: "Bell",
    bellNotification: "Bell_Notification",
    error: "Error",
    info: "Info",
    roadAssistance: "Road_Assistance",
    calculator: "Calculator",
    movingTool: "Moving_Tool",
    personalizedFeedbackStatistic: "Personalized_Feedback_Statistic",
    repair: "Repair",
    settings: "Settings",
    tool: "Tool",
    treat: "Treat",
    engineTemperature: "Engine_Temperature",
    errorRing: "Error_Ring",
    ev: "EV",
    meters: "Meters",
    mileage: "Mileage",
    speed: "Speed",
    speed1: "Speed-1",
    speedometer: "Speedometer",
    steeringWheelAlert: "Steering_Wheel-_Alert",
    airVent: "Air_Vent",
    airbag: "Airbag",
    battery: "Battery",
    engine: "Engine",
    interiorCar: "Interior_Car",
    mobility: "Mobility",
    partsSales: "Parts_Sales",
    steeringWheel: "Steering_Wheel",
    tyres: "Tyres",
    wheel: "Wheel",
    wheelSafety: "Wheel_Safety",
    accelerations: "Accelerations",
    batteryElectricCarBEV: "Battery_Electric_Car_BEV",
    car: "Car",
    carsExchange: "Cars_Exchange",
    collision: "Collision",
    dieselPetrolCar: "Diesel_Petrol_Car",
    exceptionalEvent: "Exceptional_Event",
    hardBrakes: "Hard_Brakes",
    hybridElectricCar: "Hybrid_Electric_Car",
    hydrogenCar: "Hydrogen_Car",
    lcv: "LCV",
    mildHybridCar: "Mild_Hybrid_Car_MHV",
    pickUpService: "Pick_Up_Service",
    pluginHybridCarPHEV: "Plug_In_Hybrid_Car_PHEV",
    repairsWorkshop: "Repairs_Workshop",
    roadServiceAssistance: "Road_Service_Assistance",
    sensor: "Sensor",
    theftFire: "Theft_Fire",
    vehicleReplacement: "Vehicle_Replacement",
    facebook: "Facebook",
    instagram: "Instagram",
    linkedin: "LinkedIn",
    telegram: "Telegram",
    tiktok: "TikTok",
    twitter: "Twitter",
    vk: "VK",
    whatsapp: "WhatsApp",
    yandexzen: "Yandex-Zen",
    youtube: "Youtube",
    gearbox: "Gearbox",
};

type IconTypes = {
    variant: keyof typeof iconVariants;
    size?: "small" | "medium" | "large";
};

/**
 * Icon
 * @description Icons from TME Icon font
 */
export const Icon = styled.span.attrs<IconTypes>((props) => ({
    className: `dxp-icon icon-${iconVariants[props.variant]}-${props.size || "small"}`,
    role: "img",
    "aria-hidden": "true",
}))<IconTypes>``;

/**
 * Icon
 * @description Optimized icons from iO
 */
export const IconCart = SvgCart;
export const IconPhone = SvgPhone;
export const IconHeart = SvgHeart;
export const IconHeartFilled = SvgHeartFilled;
export const IconExclamation = SvgException;
export const IconTag = SvgTag;
export const IconPlusCircle = SvgPlusCircle;
export const IconCartLeasing = SvgCartLeasing;
export const IconCartNegative = SvgCartNegative;
export const IconDelivery = SvgDelivery;
export const IconBuy = SvgBuy;
export const IconStar = SvgStar;
export const IconStarFilled = SvgStarFilled;
export const IconCheckmark = SvgCheckmark;
export const IconCarExchange = SvgCarExchange;
export const IconArrowRight = SvgArrowRight;
export const IconChevronDown = SvgChevronDown;
export const IconGearbox = SvgGearbox;
export const IconFullscreen = SvgFullscreen;
export const IconCalculator = SvgCalculator;
export const IconCarSimilarCars = SvgCarSimilarCars;
export const IconEdit = SvgEdit;
export const IconPlus = SvgPlus;
export const IconInfo = SvgInfo;
export const IconProfile = SvgProfile;
export const IconApproved = SvgApproved;
export const IconNonApproved = SvgNonApproved;
export const IconSearch = SvgSearch;
export const IconFilters = SvgFilters;

// Legacy
export const IconChevronRight = SvgChevronRight;
export const IconChevronLeft = SvgChevronLeft;
export const IconTradeIn = SvgTradeIn;
export const IconClose = SvgClose;
export const IconSort = SvgSort;
export const IconControls = SvgControls;
export const IconMarker = SvgMarker;
export const IconLocation = SvgLocation;

/**
 * @deprecated
 */
export const IconRainbowCircle = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAHgUExURUdwTJ+qv6+Pn/S0Op+vz8/fb+9vL++/P+9fP/+fL6qftPC1OfSsNfC6O7DNhvJsObPPg7nRe/BpO+paQ/RvNfegLuvDQbt/ketfQexgQOvDQK2Tp/NzNPWjMeNHT8TUdN9PT/BrOcPVc/NuN99LU6/Pj/SqL6Ojue/PP+nLRenJRKHHnaLHnfd/K+VMTPmVK81lc+lXRcVwgK2VqpTEv6zMjL18jprFqt9PX/uJKvmTK/mEKvuMK79/n5/Pj9lSXOfPR4/B2dvbVuTXSpTByZS30vmCLZquyJPDz93bU4+/2/d5MPd/L/h7MPV0NJK71pC92fd8MJ6qwtPYX/d5Mfd/L8fWbZnEstTYYOTZSufTR+jPRo+/2fd8L5S41Ph+L5DB2/h9L/G0OPStNe+7PLrRfLLPhedSSPJtOe5jP+9nPPNwN+hUR+7APsLUdeRJTfamMutdQvRyNvieLqOju+vHQt1PWaics7GPpM5lc7aIm6bKmavMkNxRW/qPK8F3iNFgbfuGK+RKTfmYLejNRubWSZ3GppTCzdjaW+PbTvmBLZC+2pO71pbDu5C/2+fQR+fQSMvXa+HcUfZ4MvuHKpa20Jmyy+bVSfV2NJTCy+nMRdDZZZ6qwvmALZfEtvd7MLMGcW0AAABfdFJOUwAwEDAQEBAQEBAwn+/fn1BA79/fMO+AgIDv7++fn0AwEJ+f70AQMO8Qj++A70BQgI+P358w3+/vEI/vMN8QEGBAYN9gYI9g70CPQI8g3+/fj4+fcO9A74/vMEBQMGDv71sSmQAAAOpJREFUGNNjYMAHrHVDfVBFOPVtGufGJ/p6syHE1ESb66Z0xSfOn+wnAhNj5ikotrJ0iw0MmVGkzA8RY+dNSpblBLHYZLIzhDjAgmK5KdwwTYpZmRJghlapFDtMkEOyUBhEa7TM0kTYaZiTrgCkHCb0RyME9Zry5YBU1PRWZ4SgfW2ZCpCK7JzkiRA0qGywANFOHRGsMDFbs3ojMCN49kQlmKBxRZU4mMEalpfKZwdisQSVlEszQaTlBdNqdMJdHAPips1R54JpEjCtbps3M6G7Z6o7I8JOVW3z3vaEPn9XFtQgNfHwisEbCwCw0jL4tzcnHAAAAABJRU5ErkJggg==);
    background-size: 10px 10px;
`;

export default Icon;
