import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GradeSelectorStateType } from "../store";

export type GradeSelectorCompareType = {
    maxItems: number;
    selectedGrades: (string | null)[];
    currentFilter?: string;
};

const initialState: GradeSelectorCompareType = {
    maxItems: 0,
    selectedGrades: [null, null, null],
    currentFilter: "highlights",
};

export const gradeSelectorCompareSlice = createSlice({
    name: "gradeSelectorCompare",
    initialState,
    reducers: {
        init(state, action: PayloadAction<number>) {
            state.maxItems = action.payload;
        },
        setSelectedGrade(state, action: PayloadAction<{ index: number; carId: string | null }>) {
            state.selectedGrades[action.payload.index] = action.payload.carId;
        },
        setFilter(state, action: PayloadAction<string | undefined>) {
            state.currentFilter = action.payload;
        },
    },
});

export const { init, setSelectedGrade, setFilter } = gradeSelectorCompareSlice.actions;

export const selectCompareMaxItems = (state: GradeSelectorStateType): GradeSelectorCompareType["maxItems"] =>
    state.gradeSelectorCompare?.maxItems ?? 0;

export const selectCompareItems = (state: GradeSelectorStateType): GradeSelectorCompareType["selectedGrades"] =>
    state.gradeSelectorCompare?.selectedGrades ?? [null, null, null];

export const selectCompareFilter = (state: GradeSelectorStateType): GradeSelectorCompareType["currentFilter"] =>
    state.gradeSelectorCompare?.currentFilter;
