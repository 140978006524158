import {
    EFFICIENCY_CLASS_TMEF,
    EFFICIENCY_CLASS,
    EFFICIENCY_CLASS_LEXUS,
    NewCarFuelTypeFilterId,
} from "../../../../common-deprecated/constants";
import {
    ApiColourType,
    ApiGradeBodyEngineTransmissionWheeldriveType,
    ApiGradeVisualForGradeConfigType,
    ApiPriceType,
} from "../../../../common-deprecated/types/ApiModel";
import { EcoTagType } from "../../../../common-deprecated/types/CommonTypes";
import { getGrLogo as getGrLogoCommon } from "../../../../common-deprecated/utils";
import { GradeSelectorFiltersType } from "../redux/slices/GradeSelectorFiltersSlice";
import {
    ColorType,
    EngineSpecType,
    EquipmentGroupType,
    GradeBodyTypeEngineTransmissionType,
    GradeBodyTypeEngineTransmissionWheeldriveType,
    GradeBodyTypeEngineType,
    GradeBodyTypeType,
} from "./types/Model";

// Info
export const getName = (gradeBodyType: GradeBodyTypeType): string => {
    const car = gradeBodyType.engines[0]?.transmissions[0]?.wheeldrives[0]?.car;
    return `${car.WebTitleSections.Model} ${car.WebTitleSections.Grade}`.trim();
};

export const diffWeeks = (dt2: Date, dt1: Date): number => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    return Math.abs(Math.round(diff));
};

// Format is `${date.getFullYear()}${`0${date.getMonth() + 1}`.substr(-2)}${date.getDate()}`
export const getEta = (gradeBodyType: GradeBodyTypeType): number | null => {
    const eta = gradeBodyType.engines[0]?.transmissions[0]?.wheeldrives[0]?.eta;

    if (typeof eta !== "string") {
        return null;
    }

    // Make ISO date
    const isoEta = `${eta[0]}${eta[1]}${eta[2]}${eta[3]}-${eta[4]}${eta[5]}-${eta.length === 8 ? eta[6] : "0"}${
        eta[eta.length === 8 ? 7 : 6]
    } 00:00:00`;

    return diffWeeks(new Date(isoEta), new Date());
};
export const getGRLogo = (gradeBodyType: GradeBodyTypeType): ReturnType<typeof getGrLogoCommon> => {
    const car = gradeBodyType.engines[0]?.transmissions[0]?.wheeldrives[0]?.car;
    return getGrLogoCommon(car.WebTitleSections.Model, car.WebTitleSections.Grade);
};

// Specs
export const getSpec = (
    wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType,
    specCode: string,
): EngineSpecType | undefined => wheeldrive.specs.find(({ code }) => code === specCode);

export const getEcoSpec = (wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType): EngineSpecType | undefined =>
    wheeldrive.specs.find(
        ({ code }) =>
            code.toUpperCase() === EFFICIENCY_CLASS_TMEF ||
            code.toUpperCase() === EFFICIENCY_CLASS ||
            code.toUpperCase() === EFFICIENCY_CLASS_LEXUS,
    );

export type MotorizationItemType = {
    engine: GradeBodyTypeEngineType;
    transmission: GradeBodyTypeEngineTransmissionType;
    wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType;
};

export const getMotorizations = (
    gradeBodyType: GradeBodyTypeType,
    activeFilters?: GradeSelectorFiltersType,
): MotorizationItemType[] => {
    const motorizations: MotorizationItemType[] = [];
    gradeBodyType.engines.forEach((engine) => {
        engine.transmissions.forEach((transmission) => {
            transmission.wheeldrives.forEach((wheeldrive) => {
                if (activeFilters?.engine) {
                    const ecoTag: EcoTagType | undefined = Object.values(EcoTagType).find(
                        (value) => value === activeFilters.engine,
                    );
                    if (ecoTag !== getEcoTagType(engine)) return;
                }
                if (activeFilters?.wheelDrive && wheeldrive.code !== activeFilters.wheelDrive) return;
                if (activeFilters?.transmission && transmission.code !== activeFilters.transmission) return;

                motorizations.push({
                    engine,
                    transmission,
                    wheeldrive,
                });
            });
        });
    });

    return motorizations;
};

export const getEcoTagType = (engine: GradeBodyTypeEngineType): EcoTagType | undefined => {
    if (engine.fuel.fuelCell === true) {
        return EcoTagType.FuelCell;
    }

    if (engine.plugin === true) {
        return EcoTagType.Plugin;
    }

    if (engine.fuel.hybrid === true) {
        return EcoTagType.Hybrid;
    }

    if (engine.fuel.electric === true) {
        return EcoTagType.Electric;
    }

    if (engine.fuel.code === NewCarFuelTypeFilterId.Diesel) {
        return EcoTagType.Diesel;
    }

    if (engine.fuel.code === NewCarFuelTypeFilterId.Petrol) {
        return EcoTagType.Petrol;
    }

    return undefined;
};

export const getGradeBodyDefaultCarId = (gradeBodyType: GradeBodyTypeType): string =>
    gradeBodyType.engines[0].transmissions[0].wheeldrives[0].car.CarID;

export const getFullEngineCode = (
    engine: GradeBodyTypeEngineType,
    transmission: GradeBodyTypeEngineTransmissionType,
    wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType,
): string => `${engine.code}${transmission.code}${wheeldrive.code}`;

export const getColoursOfGradeBodyType = (gradeBodyType: GradeBodyTypeType): ColorType[] =>
    gradeBodyType.engines.flatMap((engine) =>
        engine.transmissions.flatMap((transmission) =>
            transmission.wheeldrives.flatMap((wheeldrive) => wheeldrive.colors),
        ),
    );

/**
 * Searches for the corresponding colour code that matches the state's selected colour id.
 * If no selected color id is present, it returns the code of the first color in the collection.
 */
export const getColourCodeById = (gradeBodyType: GradeBodyTypeType): string => {
    const colours = getColoursOfGradeBodyType(gradeBodyType);

    const colourCode = colours.find(({ id }) => id === gradeBodyType.selectedColourId)?.code;

    if (gradeBodyType.selectedColourId && colourCode) return colourCode;

    return colours[0].code;
};

export const getUniqueMotorizationId = (
    engine: GradeBodyTypeEngineType,
    transmission: GradeBodyTypeEngineTransmissionType,
    wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType,
): string => `${engine.id}-${transmission.id}-${wheeldrive.id}`;

export const getMotorizationData = (
    gradeBodyType: GradeBodyTypeType,
): {
    engine: GradeBodyTypeEngineType;
    transmission: GradeBodyTypeEngineTransmissionType;
    wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType;
} => {
    const { selectedMotorization } = gradeBodyType;

    const engine =
        gradeBodyType.engines.find(({ id }) => id === selectedMotorization.engineId) || gradeBodyType.engines[0];
    const transmission =
        engine?.transmissions.find(({ id }) => id === selectedMotorization.transmissionId) || engine.transmissions[0];
    const wheeldrive =
        transmission?.wheeldrives.find(({ id }) => id === selectedMotorization.wheeldriveId) ||
        transmission.wheeldrives[0];

    return { engine, transmission, wheeldrive };
};

export const calculatePriceWithColour = (
    inclVat: boolean,
    gradePrice: number,
    colourPrice: ApiPriceType | undefined,
    oldPrice = false,
): number => {
    const priceKey = oldPrice ? (inclVat ? "list" : "net") : inclVat ? "listWithDiscount" : "netWithDiscount";

    return (colourPrice?.[priceKey] ?? 0) + gradePrice;
};

export const getColourPrice = (
    colourId: string | undefined,
    selectedWheelDrive: GradeBodyTypeEngineTransmissionWheeldriveType,
): ApiPriceType | undefined => {
    return selectedWheelDrive.colors?.find(({ id }) => colourId === id)?.price;
};

/**
 * Searches in all available colours for a match of visualForGradeConfig.
 * If no match is found, the first available colour for that wheeldrive is returned.
 */
export const getColourIdFromVisualForGrade = (
    wheeldrive: ApiGradeBodyEngineTransmissionWheeldriveType,
    colours: ApiColourType[],
    visualForGradeConfig?: ApiGradeVisualForGradeConfigType,
): string | undefined => {
    const matchingWheeldriveColourCode = wheeldrive.colours.find(
        (colour) => colour === visualForGradeConfig?.colour.code,
    );

    const matchingColour = colours.find(
        (colour) => colour.code === matchingWheeldriveColourCode && colour.id === visualForGradeConfig?.colour.id,
    )?.id;

    return matchingColour ?? colours.find(({ code }) => code === wheeldrive.colours[0])?.id;
};

export const getCategoryGroupIcon = (
    equipmentGroups: EquipmentGroupType[],
    categoryRoot: string,
): "car" | "interior_car" | undefined => {
    const root = equipmentGroups.find((group) => group.root === categoryRoot)?.default.root?.toLowerCase();

    if (root === "exterior") return "car";
    else if (root === "interior") return "interior_car";

    return undefined;
};
