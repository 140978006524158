import React, { FC } from "react";
import styles from "./Legend.module.scss";
import { GradeSelectorLabel } from "../../../../../utils/labels";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import Container from "../../../../../../shared-components/css-modules/toyota/atoms/Container";

const Legend: FC = () => {
    return (
        <Container>
            <section className={styles.wrapper}>
                <GradeSelectorLabel label="gradeSelectorLegendTitle" />
                <div className={styles.options}>
                    <span className={styles.option}>
                        <Icon className={styles.icon} color="green" variant="check" sizing="sm" aria-hidden="true" />
                        <GradeSelectorLabel label="gradeSelectorLegendStandard" />
                    </span>
                    <span className={styles.option}>
                        <Icon className={styles.icon} variant="customize-black" sizing="sm" aria-hidden="true" />
                        <GradeSelectorLabel label="gradeSelectorLegendOptional" />
                    </span>
                    <span className={styles.option}>
                        <Icon className={styles.icon} variant="close" sizing="sm" aria-hidden="true" />
                        <GradeSelectorLabel label="gradeSelectorLegendNotApplicable" />
                    </span>
                </div>
            </section>
        </Container>
    );
};

export default Legend;
