import * as DOMUtils from "history/DOMUtils";
import { GradeSelectorDispatchType, GradeSelectorStateType } from "../redux/store";
import { AbortableFetchFnReturnType, FetchFnReturnType } from "../../../../common-deprecated/utils/backendFetch";
import { initGradeSelectorSettings, GradeSelectorSettingsType } from "../redux/reducers/GradeSelectorSettingsReducer";
import { init as initGradeSelectorModel } from "../redux/slices/GradeSelectorModelSlice";
import { ModelType } from "../utils/types/Model";
import { GradeSelectorFinanceType, initFinance, setFinanceState } from "../redux/slices/GradeSelectorFinanceSlice";
import { searchModelMapValue } from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { AemFmComponent } from "../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { showAemMonthlyRates } from "../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { FINANCE_TIMEOUT } from "../../../../common-deprecated/constants";

// ----------------------------------------------------------------------
// Fetch types
// ----------------------------------------------------------------------
export const FETCH_GRADE_SELECTOR_SETTINGS = "fetchGradeSelectorSettings";
export const FETCH_GRADE_SELECTOR_MODEL = "fetchGradeSelectorModel";
export const FETCH_GRADE_SELECTOR_FINANCE = "fetchGradeSelectorFinance";

export type FetchGradeSelectorSettingsType = {
    fetchType: typeof FETCH_GRADE_SELECTOR_SETTINGS;
    modelId?: string;
    result?: FetchFnReturnType<GradeSelectorSettingsType | null>;
};

export type FetchGradeSelectorModelType = {
    fetchType: typeof FETCH_GRADE_SELECTOR_MODEL;
    settings: GradeSelectorSettingsType;
    modelId?: string;
    result?: FetchFnReturnType<ModelType | null>;
};

export type FetchGradeSelectorFinanceType = {
    fetchType: typeof FETCH_GRADE_SELECTOR_FINANCE;
    modelCode: string;
    carIds: string[];
    timeout?: number;
    result?: AbortableFetchFnReturnType<GradeSelectorFinanceType["cars"] | null>;
};

export const fetchGradeSelectorSettings = (modelId?: string): FetchGradeSelectorSettingsType => ({
    fetchType: FETCH_GRADE_SELECTOR_SETTINGS,
    modelId,
});

export const fetchGradeSelectorModel = (
    settings: GradeSelectorSettingsType,
    modelId?: string,
): FetchGradeSelectorModelType => ({
    fetchType: FETCH_GRADE_SELECTOR_MODEL,
    settings,
    modelId,
});

export const fetchGradeSelectorFinance = (
    modelCode: string,
    carIds: string[],
    timeout?: number,
): FetchGradeSelectorFinanceType => ({
    fetchType: FETCH_GRADE_SELECTOR_FINANCE,
    modelCode,
    carIds,
    timeout,
});

export type GradeSelectorFetchTypes =
    | FetchGradeSelectorSettingsType
    | FetchGradeSelectorModelType
    | FetchGradeSelectorFinanceType;

export const getGradeSelectorComponentFetches =
    (modelId?: string) =>
    (state: GradeSelectorStateType): GradeSelectorFetchTypes[] => {
        const fetches: GradeSelectorFetchTypes[] = [];
        const { gradeSelectorSettings, gradeSelectorModel, gradeSelectorFinance, commonSettings } = state;

        if (!gradeSelectorSettings.initialized) {
            // Integrated grade switcher uses modelId which is passed as a prop from the state.
            // Standalone grade switcher doesn't have this data yet, use modelId from query instead.
            let modelMapModelId: string | undefined;

            if (!modelId && commonSettings.query.modelId) {
                const modelMapModel = searchModelMapValue<CommonSettingsType>(
                    commonSettings,
                    commonSettings.query.modelId,
                );
                modelMapModelId = modelMapModel?.id;
            }

            fetches.push(fetchGradeSelectorSettings(modelId ?? modelMapModelId));
        }

        if (!gradeSelectorModel.initialized && gradeSelectorSettings.initialized) {
            fetches.push(fetchGradeSelectorModel(gradeSelectorSettings, modelId));
        }

        if (
            gradeSelectorModel.initialized &&
            gradeSelectorSettings.initialized &&
            (gradeSelectorFinance.state === "idle" ||
                (DOMUtils.canUseDOM && gradeSelectorFinance.state === "aborted")) &&
            showAemMonthlyRates(commonSettings, AemFmComponent.GradeSelector, "new", gradeSelectorModel.model.code)
        ) {
            const timeout = !DOMUtils.canUseDOM ? FINANCE_TIMEOUT : undefined;
            const allCarIds = new Set<string>();
            gradeSelectorModel.model.gradeBodyTypes.forEach((gradeBodyType) => {
                gradeBodyType.engines.forEach((engine) => {
                    engine.transmissions.forEach((transmission) => {
                        transmission.wheeldrives.forEach((wheeldrive) => {
                            allCarIds.add(wheeldrive.car.CarID);
                        });
                    });
                });
            });
            fetches.push(fetchGradeSelectorFinance(gradeSelectorModel.model.code, Array.from(allCarIds), timeout));
        }

        return fetches;
    };

// ----------------------------------------------------------------------
// Dispatcher
// ----------------------------------------------------------------------
export const gradeSelectorFetchDispatcher = (
    data: Required<GradeSelectorFetchTypes>,
    dispatch: GradeSelectorDispatchType,
): void => {
    switch (data.fetchType) {
        case FETCH_GRADE_SELECTOR_SETTINGS:
            if (data.result.fetchResult) {
                dispatch(initGradeSelectorSettings(data.result.fetchResult));
            }
            break;
        case FETCH_GRADE_SELECTOR_MODEL:
            if (data.result.fetchResult) {
                dispatch(initGradeSelectorModel(data.result.fetchResult));
            }
            break;
        case FETCH_GRADE_SELECTOR_FINANCE:
            if (data.result.fetchResult) {
                dispatch(initFinance(data.result.fetchResult));
            } else {
                dispatch(setFinanceState(data.result.aborted ? "aborted" : "error"));
            }
            break;
    }
};
