import React, { FC, memo } from "react";
import clsx from "clsx";
import { CarType } from "../../../../../utils/types/Model";
import styles from "./Price.module.scss";
import CashPrice from "./CashPrice";
import MonthlyPrice from "./MonthlyPrice";
import { ApiPriceType } from "../../../../../../../../common-deprecated/types/ApiModel";

const Price: FC<{
    car: CarType;
    price: ApiPriceType;
    colourPrice?: ApiPriceType;
    showPromotionsNumbers?: boolean;
    column?: boolean;
}> = ({ car, price, colourPrice, showPromotionsNumbers, column }) => {
    return (
        <span className={clsx(styles.price, column && styles.column)}>
            <CashPrice price={price} colourPrice={colourPrice} showPromotionsNumbers={showPromotionsNumbers} />
            <MonthlyPrice car={car} price={price} />
        </span>
    );
};

export default memo(Price);
