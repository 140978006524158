import React from "react";
import clsx from "clsx";
import styles from "./Incentive.module.scss";
import Icon from "../atoms/Icon";

type IncentiveIconProps = {
    variant?: "best-seller" | "efficient";
};

const IncentiveIcon: React.FC<IncentiveIconProps> = ({ variant }) => {
    switch (variant) {
        case "efficient":
            return <Icon variant="fuelEfficiency" className={styles.icon} sizing="sm" />;
        case "best-seller":
            return <Icon variant="star" className={styles.icon} sizing="sm" />;
        default:
            return null;
    }
};

export type Props = {
    className?: string;
    variant?: "best-seller" | "efficient";
    label: string;
} & React.HTMLProps<HTMLDivElement>;

const Incentive: React.FC<Props> = ({ variant = "best-seller", label, className, ...rest }) => {
    return (
        <div className={clsx(styles[variant], className)} {...rest}>
            <IncentiveIcon variant={variant} />
            {label}
        </div>
    );
};

export default Incentive;
