import React, { FC } from "react";
import { CarType } from "../../../../../utils/types/Model";
import { FormatMonthlyPriceType, MonthlyPriceLabelKey } from "../../../../../../../../common-deprecated/priceUtils";
import { AemFmComponent } from "../../../../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { useGradeSelectorSelector } from "../../../../../redux/hooks";
import {
    getAemMonthlyRateFormat,
    showAemMonthlyRates,
} from "../../../../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import Popover from "../../../../../../shared-components/css-modules/toyota/vendor/popover/Popover";
import Button from "../../../../../../shared-components/css-modules/toyota/atoms/Button";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import { ApiPriceType } from "../../../../../../../../common-deprecated/types/ApiModel";
import {
    formatMonthlyFinancePrice,
    mapPrice,
} from "../../../../../../../../common-deprecated/features/apheleia/utils/aphPriceUtils";

const MonthlyPrice: FC<{
    car: CarType;
    price: ApiPriceType;
}> = ({ car, price }) => {
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const modelCode = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.code);
    const monthlyEnabled = showAemMonthlyRates(commonSettings, AemFmComponent.GradeSelector, "new", modelCode);
    const { state, cars } = useGradeSelectorSelector((s) => s.gradeSelectorFinance);

    if (!monthlyEnabled || state !== "complete") {
        return null;
    }

    const carFinance = cars[car.CarID];

    if (!carFinance) {
        return null;
    }

    const { financeInfo, quote } = carFinance;
    const monthlyPriceFormat = getAemMonthlyRateFormat(commonSettings, AemFmComponent.GradeSelector, "new", modelCode);
    const { labels = [] } = financeInfo
        ? formatMonthlyFinancePrice<FormatMonthlyPriceType>(
              mapPrice(price, financeInfo),
              financeInfo,
              commonSettings,
              monthlyPriceFormat,
          )
        : { labels: [] };

    if (labels.length === 0) {
        return null;
    }

    return (
        <span>
            {labels.map(({ key, value }) => (
                <span key={key}>
                    {(key === MonthlyPriceLabelKey.Price || key === MonthlyPriceLabelKey.DownPayment) && (
                        <strong>
                            <span dangerouslySetInnerHTML={{ __html: value }} />
                        </strong>
                    )}
                </span>
            ))}
            {quote && (
                <Popover placement="top-start">
                    <Popover.Trigger>
                        <Button variant="clean" type="button">
                            <Icon variant="info" sizing="sm" aria-hidden="true" />
                        </Button>
                    </Popover.Trigger>
                    <Popover.Content>
                        <div>
                            {quote.productName && <strong>{quote.productName}</strong>}
                            {quote.calculations && quote.calculations.length !== 0 && (
                                <table>
                                    <tbody>
                                        {quote.calculations.map(({ name, value, format }) => (
                                            <tr style={{ color: format.colour }} key={name}>
                                                <td>{format.b ? <strong>{name}</strong> : <span>{name}</span>}</td>
                                                <td>
                                                    {format.b ? (
                                                        <strong dangerouslySetInnerHTML={{ __html: value }} />
                                                    ) : (
                                                        <span dangerouslySetInnerHTML={{ __html: value }} />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <div dangerouslySetInnerHTML={{ __html: quote.value }} />
                        </div>
                    </Popover.Content>
                </Popover>
            )}
        </span>
    );
};

export default MonthlyPrice;
