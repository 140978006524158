import React, { useEffect } from "react";
import Selector from "./selector/Selector";
import styles from "./Gradeselector.module.scss";
import { useModalMap } from "../../../../../common-deprecated/containers/ModalProvider";
import { aemGradeSelectorToyotaModalMap } from "./modals/modals";
import { APH_GRADE_SELECTOR_ID } from "../../../../../common-deprecated/constants";
import { updateTrackingLayerVersioning } from "../../utils/tracking";
import useDataFetcher from "../../../../../common-deprecated/hooks/useDataFetcher";
import { GetFetchesFnType } from "../../../../../common-deprecated/utils/registryTypes";
import { COMPONENT_GRADE_SELECTOR } from "../../../../../shared-logic/server/components";
import { getGradeSelectorComponentFetches, gradeSelectorFetchDispatcher } from "../../datafetch/fetchUtils";
import { useGradeSelectorSelector } from "../../redux/hooks";
import useStatus from "../../../../../common-deprecated/hooks/useStatus";
import { getRetailerDebugToggleLink } from "../../../../../common-deprecated/utils";
import { MODAL_DEBUG_FINANCE } from "../../utils/modalConstants";

const GradeSelector = (): JSX.Element => {
    useModalMap(aemGradeSelectorToyotaModalMap);
    const settings = useGradeSelectorSelector((state) => state.commonSettings);

    useEffect(() => {
        updateTrackingLayerVersioning();
    }, []);

    useStatus(
        settings,
        settings.component,
        [getRetailerDebugToggleLink()],
        [{ key: "f", settings: { type: MODAL_DEBUG_FINANCE } }],
    );

    // This makes the component refetch its data when finance failed to fetch swiftly
    useDataFetcher(
        getGradeSelectorComponentFetches() as unknown as GetFetchesFnType,
        gradeSelectorFetchDispatcher,
        COMPONENT_GRADE_SELECTOR,
        [],
        [],
    );

    return (
        <div id={APH_GRADE_SELECTOR_ID} className={styles.gradeselector}>
            <Selector />
        </div>
    );
};

export default GradeSelector;
