import { trackEvent, updateTrackingBaseLayer } from "../../../../common-deprecated/utils/tracking";
import { GradeSelectorFiltersType } from "../redux/slices/GradeSelectorFiltersSlice";

const COMPONENT_NAME = "grade-selector-v3";
const COMPONENT_NAME_COMPARE = "grade-compare";
const COMPONENT_GROUP = "new-cars";
const COMPONENT_EVENT = "componentevent";
const CTA_EVENT = "ctaevent";

// Add commitHash to the base tracking layer so this will get added to all events
export const updateTrackingLayerVersioning = (): void => {
    updateTrackingBaseLayer({
        page: {
            ...((window.tngaDataLayer?.page as Record<string, unknown>) || {}),
            versions: {
                ...(window.tngaDataLayer?.page?.versions as Record<string, unknown>),
                "aph-webcarconfig": commithash,
            },
        },
    });
};

// When the content of a category is displayed (user click on category)
export const trackCategoryVisible = (state: GradeSelectorFiltersType, newCategory: string | undefined): void => {
    const root = newCategory?.split("/")[0];
    const subCategory = newCategory?.split("/")[1];
    const currentCategory = root ? state.category[root] : undefined;

    const isNewSubCategory = currentCategory?.subCategory !== (subCategory ?? newCategory);

    if (!currentCategory?.active || isNewSubCategory) {
        let trackChange = false;

        // If there is no root category, check if any filters are active.
        if (!root) {
            const activeFilter = Object.values(state.category).some((filter) => filter.active);
            trackChange = activeFilter;
        }
        // If there is a subcategory or root category is not yet active.
        else {
            trackChange = !!subCategory || !!(!subCategory && !state.category[root]?.active);
        }

        if (trackChange) {
            trackEvent({
                name: COMPONENT_EVENT,
                component: COMPONENT_NAME,
                group: COMPONENT_GROUP,
                action: "select-category",
                label: typeof newCategory === "undefined" ? "Highlights" : newCategory,
            });
        }
    }
};

// User click on "browse" arrows/swipe to see other grades
export const trackBrowseGrades = (): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "browse-grade",
    });
};

// Click to expand engine configuration options
export const trackEngineOptionsOpen = (carGradeName: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "expand-engine-options",
        label: carGradeName,
    });
};

// Click to reduce engine configuration options
export const trackEngineOptionsClose = (carGradeName: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "reduce-engine-options",
        label: carGradeName,
    });
};

// When the content of fuel economy tooltip is displayed
export const trackFuelEconomyInfo = (engineID: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "view-fuel-economy-info",
        label: engineID,
    });
};

// When the content of gas emission tooltip is displayed
export const trackGasEmissionInfo = (engineID: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "view-emission-info",
        label: engineID,
    });
};

// Click on explore and configure CTA
export const trackSelectGrade = (carGradeName: string): void => {
    trackEvent({
        name: CTA_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "select-grade",
        label: carGradeName,
    });
};

// Click on Compare button to open compare view
export const trackOpenCompare = (): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "open-compare",
    });
};

// Click to select a fuel type in the upper menu
export const trackSelectFuelType = (fuelType: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "select-fuel",
        label: fuelType,
    });
};

// Click on cross to close compare view
export const trackCloseCompare = (): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME_COMPARE,
        group: COMPONENT_GROUP,
        action: "close-compare",
    });
};

// Click on a grade to add in in comparison
export const trackAddToCompare = (carGradeName: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME_COMPARE,
        group: COMPONENT_GROUP,
        action: "add-compare",
        label: carGradeName,
    });
};

// Click to expand a section in compare
export const trackExpandSectionInCompare = (carGradeName: string, sectionName: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME_COMPARE,
        group: COMPONENT_GROUP,
        action: "expand-section",
        label: `${carGradeName}-${sectionName}`,
    });
};

// Click on explore and configure CTA in compare
export const trackCompareCTAClick = (carGradeName: string): void => {
    trackEvent({
        name: CTA_EVENT,
        component: COMPONENT_NAME_COMPARE,
        group: COMPONENT_GROUP,
        action: "select-grade",
        label: carGradeName,
    });
};
