import React, { memo, PropsWithChildren } from "react";
import clsx from "clsx";
import { ColorTypes } from "../utils/ColorTypes";
import { SpacingTypes } from "../utils/SpacingTypes";
import spacingStyles from "../utils/SpacingTypes.module.scss";
import styles from "./Text.module.scss";
import colorStyles from "../utils/ColorTypes.module.scss";

export type Props = {
    tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div";
    variant?:
        | "title-book"
        | "header-semibold"
        | "header-book"
        | "header-regular"
        | "subheader-regular"
        | "card-subheader-semibold"
        | "card-subheader-regular"
        | "header-body-regular"
        | "body-book"
        | "support-header-semibold"
        | "support-book"
        | "lead"
        | "hybrid-label"
        | "apheleia-h1"
        | "apheleia-h2"
        | "apheleia-h3"
        | "apheleia-h4"
        | "apheleia-h5"
        | "apheleia-price"
        | "apheleia-body"
        | "apheleia-hybrid-label"
        | "apheleia-border-title";
    color?: ColorTypes;
    spacing?: SpacingTypes;
    className?: string;
    skeleton?: boolean;
} & React.HTMLProps<HTMLHeadingElement>;

const Text: React.FC<PropsWithChildren<Props>> = (props) => {
    const { tag = "p", variant = "body-book", color, spacing, skeleton, className, children, ...rest } = props;

    return React.createElement(
        tag,
        {
            ...rest,
            className: clsx(
                styles[variant],
                color && colorStyles[color],
                spacing && spacingStyles[spacing],
                skeleton && styles.skeleton,
                className,
            ),
        },
        children,
    );
};

export default memo(Text);
