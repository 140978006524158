import React, { FC } from "react";
import EcoLabel from "../../../../../shared-components/css-modules/toyota/molecules/EcoLabel";
import HybridLabel from "../../../../../shared-components/css-modules/toyota/molecules/HybridLabel";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import { DisableEcoItemType, getEcoItems } from "../../../../utils/ecoUtils";
import { getGradeSelectorLabel, useGradeSelectorLabel } from "../../../../utils/labels";
import { GradeBodyTypeEngineType } from "../../../../utils/types/Model";
import styles from "./Item.module.scss";
import EfficiencyClass from "./specs/EfficiencyClass";

export const EcoItems: FC<{
    limit: number;
    modelId: string;
    engines: GradeBodyTypeEngineType[];
    disable?: DisableEcoItemType;
}> = ({ limit = 3, modelId, engines, disable }) => {
    const gradeSelectorSettings = useGradeSelectorSelector((state) => state.gradeSelectorSettings);
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);
    const multiEngineLabel = useGradeSelectorLabel("multipleEngineOptions");
    const ecoItems = getEcoItems(modelId, engines, commonSettings, disable);

    return ecoItems.length < limit ? (
        <>
            {ecoItems.map(({ ecoBadgeUrl, ecoTag, ecoTagLabel, efficiencyLabelConfig }) => (
                <div className={styles["eco-item"]} key={ecoTagLabel}>
                    {efficiencyLabelConfig.show && <EfficiencyClass label={efficiencyLabelConfig.label} />}
                    {ecoBadgeUrl && <EcoLabel url={ecoBadgeUrl} />}
                    {!disable?.ecoTag &&
                        (ecoTagLabel ? (
                            <HybridLabel
                                variant={ecoTag}
                                label={getGradeSelectorLabel({ commonSettings, gradeSelectorSettings }, ecoTagLabel)}
                                hideBackground
                            />
                        ) : (
                            <HybridLabel
                                variant="default"
                                label={getGradeSelectorLabel({ commonSettings, gradeSelectorSettings }, "standard")}
                                hideBackground
                            />
                        ))}
                </div>
            ))}
        </>
    ) : (
        <div className={styles["eco-item"]}>
            <HybridLabel variant="multi" label={multiEngineLabel} />
        </div>
    );
};
