import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSlider = (
    wrapperRef: React.MutableRefObject<HTMLDivElement | null>,
    anyItemRef: React.MutableRefObject<HTMLDivElement | null>,
    onScrolled?: () => void,
    onBrowse?: () => void,
) => {
    const isRTL = typeof window !== "undefined" ? document.documentElement.dir === "rtl" : false;
    const scrollLeft = useRef<number>(0);
    const startX = useRef<number>(0);
    const [prevButtonDisabled, setPrevButtonDisabled] = useState<boolean>(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
    const [trackScroll, setTrackScroll] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const navigate = useCallback(
        (direction: 1 | -1 = 1) =>
            () => {
                onBrowse?.();
                if (wrapperRef.current) {
                    const anyItemWidth = anyItemRef.current?.clientWidth || 0;
                    wrapperRef.current.scrollTo({
                        left: wrapperRef.current.scrollLeft + anyItemWidth * direction,
                        behavior: "smooth",
                    });
                }
            },
        [wrapperRef, anyItemRef, onBrowse],
    );

    const onMouseDown = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (wrapperRef.current) {
                setIsDragging(true);

                startX.current = event.pageX - wrapperRef.current.offsetLeft;
                scrollLeft.current = wrapperRef.current.scrollLeft;
            }
        },
        [wrapperRef],
    );

    const onMouseLeave = useCallback(() => {
        if (wrapperRef.current) {
            setIsDragging(false);
        }
    }, [wrapperRef]);

    const onMouseUp = useCallback(() => {
        if (wrapperRef.current) {
            setIsDragging(false);
        }
    }, [wrapperRef]);

    const onMouseMove = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (wrapperRef.current) {
                if (isDragging === false) return;
                const x = event.pageX - wrapperRef.current.offsetLeft;
                const walk = (x - startX.current) * 2; //scroll-fast
                wrapperRef.current.scrollLeft = scrollLeft.current - walk;
            }
        },
        [wrapperRef, isDragging],
    );

    const setButtonStates = useCallback(() => {
        if (wrapperRef.current) {
            const target = wrapperRef.current;
            // For RTL, the scrollLeft is negative
            if (isRTL) {
                setPrevButtonDisabled(target.scrollWidth - target.offsetWidth - Math.abs(target.scrollLeft) <= 1);
                setNextButtonDisabled(target.scrollLeft === 0);
            } else {
                setPrevButtonDisabled(target.scrollLeft === 0);
                setNextButtonDisabled(target.scrollWidth - target.offsetWidth - target.scrollLeft <= 1);
            }
        }
    }, [wrapperRef]);

    const onPointerDown = useCallback(() => {
        setTrackScroll(true);
    }, []);

    const onPointerMove = useCallback(() => {
        if (trackScroll) {
            onScrolled?.();
            setTrackScroll(false);
        }
    }, [trackScroll, onScrolled]);

    const onPointerLeave = useCallback(() => {
        setTrackScroll(false);
    }, []);

    const onPointerUp = useCallback(() => {
        setTrackScroll(false);
    }, []);

    const onScroll = useCallback(() => {
        setButtonStates();

        if (wrapperRef.current && anyItemRef.current) {
            setCurrentSlide(Math.round(wrapperRef.current.scrollLeft / anyItemRef.current?.clientWidth));
        }
    }, [setButtonStates, wrapperRef, anyItemRef]);

    useEffect(() => {
        setButtonStates();
    }, [setButtonStates]);

    return {
        navigate,
        prevButtonDisabled,
        nextButtonDisabled,
        isDragging,
        currentSlide,
        elementProps: {
            onMouseDown,
            onMouseLeave,
            onMouseUp,
            onMouseMove,
            onPointerDown,
            onPointerMove,
            onPointerLeave,
            onPointerUp,
            onScroll,
        },
    };
};
