import { EcoTagType } from "../types/CommonTypes";

export type EcoTagLabelType =
    | "hybrid"
    | "fuelCell"
    | "plugin"
    | "electric"
    | "hybridAvailable"
    | "diesel"
    | "petrol"
    | undefined;

export const ecoTagToLabel = (ecoTag?: EcoTagType): EcoTagLabelType => {
    switch (ecoTag) {
        case EcoTagType.Available:
            return "hybridAvailable";
        case EcoTagType.FuelCell:
            return "fuelCell";
        case EcoTagType.Plugin:
            return "plugin";
        case EcoTagType.Hybrid:
            return "hybrid";
        case EcoTagType.Electric:
            return "electric";
        case EcoTagType.Diesel:
            return "diesel";
        case EcoTagType.Petrol:
            return "petrol";
        default:
            return undefined;
    }
};
