import { getUniqueGradeBodyTypeId } from "../../../../common-deprecated/features/apheleia/utils/aphUtils";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { EcoTagType } from "../../../../common-deprecated/types/CommonTypes";
import { ecoTagToLabel } from "../../../../common-deprecated/utils/ecoTagUtils";
import { GradeSelectorSettingsType } from "../redux/reducers/GradeSelectorSettingsReducer";
import { GradeSelectorFiltersType } from "../redux/slices/GradeSelectorFiltersSlice";
import { GradeSelectorFilterId } from "./filterConstants";
import { getEcoTagType } from "./gradeUtils";
import { GradeSelectorLabelType, getGradeSelectorLabel } from "./labels";
import { GradeBodyTypeEngineType, GradeBodyTypeType } from "./types/Model";

export type FilterDataType = {
    uniqueGradeBodyTypeIds: string[];
    code: string;
    name: string;
    swatch?: { asset?: string; rgb?: string };
    sortable?: string;
};

/**
 * Returns the possible filters for the current grades.
 *
 * @param filterId The type of filter which needs this data.
 * @param grades The active grades.
 * @param commonSettings Needed to get the correct ecoTag label.
 * @param gradeSelectorSettings to get the correct ecoTag label.
 *
 * @returns FilterDataType[] which contains the name of the possible filter values, the amount of corresponding grades...
 * and in case of colour, the asset + RGB and id of the colour.
 */
export const getFilterData = (
    filterId: GradeSelectorFilterId,
    grades: GradeBodyTypeType[],
    commonSettings: CommonSettingsType,
    gradeSelectorSettings: GradeSelectorSettingsType,
): FilterDataType[] => {
    const result: { [key: string]: FilterDataType } = {};

    const enginesPerGradyBodyType = grades.map(({ engines, grade, bodyType, submodel }) => {
        const uniqueId = getUniqueGradeBodyTypeId(grade.id, bodyType.id, submodel?.id);
        return { engines, uniqueId };
    });

    switch (filterId) {
        // Find all the available engine types (ecoTags).
        case GradeSelectorFilterId.Engine: {
            enginesPerGradyBodyType.forEach(({ engines, uniqueId }) => {
                engines.forEach((engine) => {
                    const ecoTag = getEcoTagType(engine);
                    if (ecoTag) {
                        if (result[ecoTag]) {
                            if (!result[ecoTag].uniqueGradeBodyTypeIds.includes(uniqueId)) {
                                result[ecoTag].uniqueGradeBodyTypeIds.push(uniqueId);
                            }
                        } else {
                            const ecoTagLabel = ecoTagToLabel(ecoTag);
                            const label = ecoTagLabel
                                ? getGradeSelectorLabel({ gradeSelectorSettings, commonSettings }, ecoTagLabel)
                                : ecoTag;
                            result[ecoTag] = {
                                uniqueGradeBodyTypeIds: [uniqueId],
                                code: ecoTag,
                                name: label,
                            };
                        }
                    }
                });
            });
            break;
        }
        // Find all the available wheeldrives (FWD, AWD, ...).
        case GradeSelectorFilterId.WheelDrive: {
            enginesPerGradyBodyType.forEach(({ engines, uniqueId }) => {
                engines.forEach(({ transmissions }) =>
                    transmissions.forEach(({ wheeldrives }) =>
                        wheeldrives.forEach(({ code, name }) => {
                            if (result[code]) {
                                if (!result[code].uniqueGradeBodyTypeIds.includes(uniqueId)) {
                                    result[code].uniqueGradeBodyTypeIds.push(uniqueId);
                                }
                            } else {
                                result[code] = { uniqueGradeBodyTypeIds: [uniqueId], code, name };
                            }
                        }),
                    ),
                );
            });
            break;
        }
        // Find all the available transmission types (manual, automatic, ...).
        case GradeSelectorFilterId.Transmission: {
            enginesPerGradyBodyType.forEach(({ engines, uniqueId }) => {
                engines.forEach(({ transmissions }) =>
                    transmissions.forEach(({ code, name }) => {
                        if (result[code]) {
                            if (!result[code].uniqueGradeBodyTypeIds.includes(uniqueId)) {
                                result[code].uniqueGradeBodyTypeIds.push(uniqueId);
                            }
                        } else {
                            result[code] = { uniqueGradeBodyTypeIds: [uniqueId], code, name };
                        }
                    }),
                );
            });
            break;
        }
        // Find all the available colours.
        case GradeSelectorFilterId.Colour: {
            enginesPerGradyBodyType.forEach(({ engines, uniqueId }) => {
                engines.forEach(({ transmissions }) =>
                    transmissions.forEach(({ wheeldrives }) =>
                        wheeldrives.forEach(({ colors }) => {
                            return colors.forEach(({ name, id, asset, rgb, type }) => {
                                if (result[id]) {
                                    if (!result[id].uniqueGradeBodyTypeIds.includes(uniqueId)) {
                                        result[id].uniqueGradeBodyTypeIds.push(uniqueId);
                                    }
                                } else {
                                    result[id] = {
                                        uniqueGradeBodyTypeIds: [uniqueId],
                                        code: id,
                                        name: `${type} - ${name}`,
                                        swatch: { asset, rgb },
                                        sortable: type,
                                    };
                                }
                            });
                        }),
                    ),
                );
            });

            break;
        }
    }

    return sortFilterData(Object.values(result));
};

/**
 * If the data has a predefined value to sort on, sort based on that. By default they get sorted alphabetically on code so they wouldn't jump around when their amount changes.
 */
const sortFilterData = (filterData: FilterDataType[]): FilterDataType[] => {
    return filterData.sort((a, b) => {
        if (a.sortable && b.sortable) {
            const sortIndexComparison = a.sortable.localeCompare(b.sortable);
            if (sortIndexComparison !== 0) {
                return sortIndexComparison;
            }
        }
        return a.code.localeCompare(b.code);
    });
};

export const hasActiveFilters = (activeFilters: GradeSelectorFiltersType | undefined): boolean => {
    return activeFilters ? Object.keys(activeFilters).filter((key) => key !== "category").length > 0 : false;
};

export const getActiveFilterAmount = (activeFilters: GradeSelectorFiltersType): number =>
    Object.keys(activeFilters).filter((key) => key !== "category").length;

export const hasActiveFilter = (
    filterId: GradeSelectorFilterId,
    activeFilters: GradeSelectorFiltersType | undefined,
): boolean => {
    const activeCheckboxFilter = !!(filterId === GradeSelectorFilterId.Colour && activeFilters?.colour);
    const activeRadioFilter = !!(filterId !== GradeSelectorFilterId.Colour && activeFilters?.[filterId]);

    return activeCheckboxFilter || activeRadioFilter;
};

export const getFilterButtonLabel = (filterId: GradeSelectorFilterId): GradeSelectorLabelType => {
    switch (filterId) {
        case GradeSelectorFilterId.Engine:
            return "gradeSelectorFilterEngineButton";
        case GradeSelectorFilterId.WheelDrive:
            return "gradeSelectorFilterWheeldriveButton";
        case GradeSelectorFilterId.Transmission:
            return "gradeSelectorFilterTransmissionButton";
        default:
            return "gradeSelectorFilterColourButton";
    }
};

export const getResultButtonLabel = (isDisabled: boolean, filterResultAmount: number): GradeSelectorLabelType => {
    if (isDisabled || filterResultAmount === 0) {
        return "gradeSelectorFilterSeeResultsDisabled";
    } else if (filterResultAmount === 1) {
        return "gradeSelectorFilterSeeResult";
    } else {
        return "gradeSelectorFilterSeeResults";
    }
};

/**
 * Helper function for applyFilters which will filter out incompatible engines for a gradeBodyType.
 */
const applyEngineFilters = (grade: GradeBodyTypeType, engines: GradeBodyTypeEngineType[]): GradeBodyTypeType | null => {
    return engines.length > 0
        ? {
              ...grade,
              engines,
          }
        : null;
};

/**
 * Applies the active filters to a list of gradeBodyTypes and returns it.
 */
export const applyFilters = (
    activeFilters: GradeSelectorFiltersType,
    gradeBodyTypes: GradeBodyTypeType[],
): GradeBodyTypeType[] => {
    const filters: ((grade: GradeBodyTypeType) => GradeBodyTypeType | null)[] = [];

    if (activeFilters.engine) {
        const ecoTag: EcoTagType | undefined = Object.values(EcoTagType).find(
            (value) => value === activeFilters.engine,
        );

        if (ecoTag) {
            filters.push((grade: GradeBodyTypeType): GradeBodyTypeType | null => {
                const engines = grade.engines.filter((engine) => {
                    return (getEcoTagType(engine) ?? "standard").includes(ecoTag);
                });

                return applyEngineFilters(grade, engines);
            });
        }
    }

    if (activeFilters.wheelDrive) {
        filters.push((grade: GradeBodyTypeType): GradeBodyTypeType | null => {
            const engines = grade.engines.filter((engine) => {
                return engine.transmissions.some(({ wheeldrives }) =>
                    wheeldrives.some(({ code }) => code === activeFilters.wheelDrive),
                );
            });

            return applyEngineFilters(grade, engines);
        });
    }

    if (activeFilters.transmission) {
        filters.push((grade: GradeBodyTypeType): GradeBodyTypeType | null => {
            const engines = grade.engines.filter((engine) => {
                return engine.transmissions.some(({ code }) => code === activeFilters.transmission);
            });

            return applyEngineFilters(grade, engines);
        });
    }

    if (activeFilters.colour?.length) {
        filters.push((grade: GradeBodyTypeType): GradeBodyTypeType | null => {
            const engines = grade.engines.filter((engine) => {
                return engine.transmissions.some(({ wheeldrives }) =>
                    wheeldrives.some(({ colors }) => colors.some(({ id }) => activeFilters.colour.includes(id))),
                );
            });

            return applyEngineFilters(grade, engines);
        });
    }

    return filters.reduce((arr, filter) => {
        return arr.map(filter).filter((item): item is GradeBodyTypeType => item !== null);
    }, gradeBodyTypes);
};

export type FilterDataToRenderType = {
    filterId: GradeSelectorFilterId;
    key: GradeSelectorLabelType;
    data: FilterDataType[];
};

/**
 * Prepares the data set in order to render all the available filters all at once in the mobile filter modal.
 * forceFilterId can be provided to only check a certain filter instead of all filters.
 */
export const prepareCombinedFilterData = (
    filteredGradeBodyTypes: GradeBodyTypeType[],
    allGradeBodyTypes: GradeBodyTypeType[],
    activeFilters: GradeSelectorFiltersType,
    commonSettings: CommonSettingsType,
    gradeSelectorSettings: GradeSelectorSettingsType,
    forcedFilterId?: GradeSelectorFilterId,
): FilterDataToRenderType[] => {
    return (forcedFilterId ? [forcedFilterId] : Object.values(GradeSelectorFilterId))
        .map((filterId) => {
            // We need to have the filterData according to the already filtered list of gradeBodyTypes.
            // But as all filters are shown in this modal, we also need to check the filterData for the complete unfiltered gradeBodyTypes list.
            // These will be displayed with a disabled state.
            const filterData = getFilterData(filterId, filteredGradeBodyTypes, commonSettings, gradeSelectorSettings);
            const allFilterData = getFilterData(filterId, allGradeBodyTypes, commonSettings, gradeSelectorSettings)
                .filter(({ code }) => !filterData.find((dat) => dat.code === code))
                .map((data) => {
                    // Note that the filters need to be applied in order to have the result amount, even before a filter is actually clicked on.
                    const amount = applyFilters({ ...activeFilters, [filterId]: data.code }, allGradeBodyTypes).length;

                    return { ...data, uniqueGradeBodyTypeIds: data.uniqueGradeBodyTypeIds.slice(0, amount) };
                });

            // As mentioned above, active filter data is now concatenated with the remaining filter data as they all need to be shown in this modal.
            // We have to redo the sorting as this is combined filter data where the sorting is no longer valid.
            const data = sortFilterData(filterData.concat(allFilterData));

            const buttonLabel = getFilterButtonLabel(filterId);

            return { key: buttonLabel, data, filterId };
        })
        .filter((filter) => {
            // Don't show filter buttons if they only contain 1 value.
            // But always show active filters.
            const activeFilter = hasActiveFilter(filter.filterId, activeFilters);
            return !(filter.data.length < 2 && !activeFilter);
        });
};

export const isResultButtonDisabled = (
    filterId: GradeSelectorFilterId,
    selectedFilters: string[],
    activeFilters: GradeSelectorFiltersType | undefined,
): boolean => {
    const isRadioFilter = filterId !== GradeSelectorFilterId.Colour;
    const isInvalidRadioFilter =
        selectedFilters.length === 0 || activeFilters?.[filterId]?.toString() === selectedFilters[0];
    const noColourSelected =
        filterId === GradeSelectorFilterId.Colour &&
        !activeFilters?.colour?.length &&
        (!selectedFilters.length || activeFilters?.colour?.toString() === selectedFilters.toString());

    return (isRadioFilter && isInvalidRadioFilter) || noColourSelected;
};

export const getFilterResultAmount = (
    filterId: GradeSelectorFilterId,
    filterData: FilterDataType[],
    selectedFilters: string[],
): number => {
    if (filterId === GradeSelectorFilterId.Colour) {
        const uniqueGradeBodyTypeIdsSet = new Set<string>();
        filterData
            .filter((filter) => selectedFilters.includes(filter.code))
            .forEach(({ uniqueGradeBodyTypeIds }) =>
                uniqueGradeBodyTypeIds.forEach((id) => uniqueGradeBodyTypeIdsSet.add(id)),
            );
        return uniqueGradeBodyTypeIdsSet.size;
    } else {
        return filterData[Number(selectedFilters)]?.uniqueGradeBodyTypeIds?.length ?? 0;
    }
};

export const getCategoryFilterSubCategory = (
    root: string,
    state: GradeSelectorFiltersType,
    category: string | undefined,
    allowClose: boolean,
): string | undefined => {
    const subCategory = category?.split("/")[1];
    const stateContainsSubCategory = !!state.category[root]?.subCategory;

    const closeSubcategory = allowClose && state.category[root]?.subCategory === subCategory;
    const keepSubCategory =
        stateContainsSubCategory && (state.category[root]?.subCategory === category || !subCategory);

    if (closeSubcategory) return undefined;
    if (keepSubCategory) return state.category[root].subCategory;
    return subCategory;
};
