import React from "react";
import styles from "./EcoLabel.module.scss";

export type Props = {
    url: string;
} & React.HTMLProps<HTMLImageElement>;

const EcoLabel: React.FC<Props> = ({ url, ...rest }) => {
    return <img src={url} className={styles.ecolabel} alt="" {...rest} />;
};

export default EcoLabel;
