import React, { CSSProperties, memo } from "react";
import clsx from "clsx";
import styles from "./Swatch.module.scss";

type Props = {
    className?: string;
    variant?: "default" | "md" | "sm";
    fill?: string;
    image?: string;
    active?: boolean;
    hasGradient?: boolean;
    renderForPrint?: boolean; // Background images and colors do not get rendered when printed, this flag is only for print usage so it would render an img instead
} & React.HTMLProps<HTMLDivElement>;

const Swatch: React.FC<Props> = ({
    variant = "default",
    fill,
    image,
    active,
    hasGradient,
    className,
    renderForPrint = false,
    ...rest
}) => {
    let style: CSSProperties | undefined;
    if (!renderForPrint) {
        style = image ? { backgroundImage: `url(${image})` } : { backgroundColor: fill };
    }

    return (
        <div
            className={clsx(styles[variant], hasGradient && styles["has-gradient"], active && styles.active, className)}
            style={style}
            {...rest}
        >
            {renderForPrint && image && <img src={image} alt="" />}
        </div>
    );
};

export default memo(Swatch);
