import React from "react";
import clsx from "clsx";
import styles from "./HybridLabel.module.scss";
import { SpacingTypes } from "../utils/SpacingTypes";
import Icon from "../atoms/Icon";
import spacingStyles from "../utils/SpacingTypes.module.scss";
import { EcoTagType } from "../../../../../../common-deprecated/types/CommonTypes";

type HybridIconProps = {
    variant?: "default" | EcoTagType | "multi";
};

const HybridIcon: React.FC<HybridIconProps> = ({ variant }) => {
    switch (variant) {
        case EcoTagType.Electric:
            return <Icon variant="plug-in" className={styles.icon} />;
        case EcoTagType.Plugin:
            return <Icon variant="drop-plugin" className={clsx(styles.icon, styles["drop-icon"])} sizing="sm" />;
        case EcoTagType.Hybrid:
            return <Icon variant="drop-hybrid" className={clsx(styles.icon, styles["drop-icon"])} sizing="sm" />;
        case "multi":
            return <Icon variant="engine" className={clsx(styles.icon, styles["drop-icon"])} sizing="sm" />;
        case "default":
        case EcoTagType.Diesel:
        case EcoTagType.Petrol:
            return <Icon variant="petrol" className={clsx(styles.icon, styles["droplet-icon"])} sizing="sm" />;
        default:
            return null;
    }
};

export type Props = {
    className?: string;
    variant?: "default" | EcoTagType | "multi";
    spacing?: SpacingTypes;
    label: string;
    hideBackground?: boolean;
} & React.HTMLProps<HTMLSpanElement>;

const HybridLabel: React.FC<Props> = ({ variant = "default", label, hideBackground, className, spacing, ...rest }) => {
    return (
        <span
            className={clsx(
                styles[variant],
                hideBackground && styles.clean,
                spacing && spacingStyles[spacing],
                className,
            )}
            {...rest}
        >
            <HybridIcon variant={variant} />
            {label}
        </span>
    );
};

export default HybridLabel;
