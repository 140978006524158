import { useGradeSelectorSelector } from "../redux/hooks";
import { selectModel } from "../redux/slices/GradeSelectorModelSlice";
import { PromotionType } from "./types/Model";

export const usePromotions = (ids?: string[]): PromotionType[] => {
    const { promotions } = useGradeSelectorSelector(selectModel);
    const filteredPromotions = promotions.filter(({ label }) => label !== null);

    if (ids) {
        return filteredPromotions.filter(({ id }) => ids.includes(id));
    }

    return filteredPromotions;
};
