import React, { memo } from "react";
import clsx from "clsx";
import { useUniqueId } from "../../../../../../common-deprecated/hooks";
import { SpacingTypes } from "../utils/SpacingTypes";
import spacingStyles from "../utils/SpacingTypes.module.scss";
import styles from "./Radio.module.scss";
import Text from "../atoms/Text";

export type Props = {
    className?: string;
    label: string; // Label of the radio.
    amount?: number;
    hideLabel?: boolean; // Give label a sr-only class.
    variant?: "default";
    invalid?: boolean; // Invalid styling.
    showRequired?: boolean; // TODO: Show required, not sure if this will be needed.
    spacing?: SpacingTypes;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Radio: React.FC<Props> = ({
    label,
    amount,
    variant = "default",
    invalid,
    showRequired,
    hideLabel,
    spacing,
    className,
    ...rest
}) => {
    const uniqueId = useUniqueId();

    return (
        <div className={clsx(styles[variant], spacing && spacingStyles[spacing], invalid && styles.invalid, className)}>
            <input id={uniqueId} type="radio" className={styles.input} {...rest} />
            <label htmlFor={uniqueId} className={clsx(hideLabel && styles["sr-only"], showRequired && styles.required)}>
                <Text>
                    {label} <span>{amount}</span>
                </Text>
            </label>
        </div>
    );
};

export default memo(Radio);
