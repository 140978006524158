import {
    getLabelFn,
    GetLabelType,
    getOptionalLabelFn,
    GetOptionalLabelType,
    getUseLabel,
    getUseOptionalLabel,
    UseLabelType,
    UseOptionalLabelType,
} from "../../../../common-deprecated/hooks/useLabel";
import { GradeSelectorStateType } from "../redux/store";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { GradeSelectorSettingsType } from "../redux/reducers/GradeSelectorSettingsReducer";
import {
    getLabelComponent,
    getMemoLabelComponent,
    LabelComponentType,
} from "../../../../common-deprecated/components/label/Label";
import { T1Labels } from "../../../../common-deprecated/utils/t1labels";

export const gradeSelectorLabels = {
    ...T1Labels,

    // Grade selector
    mainTitle: "Which <strong>{model}</strong> will you choose?",
    mainSubtitle: "See the prices and costs",
    gradeCtaButton: "Explore & configure",
    tabsHighlights: "Highlights",
    availableColours: "Available colours",
    compare: "Compare",
    // Only plural as we dont expect a delivery of car in a week
    eta: "Delivery in {num} weeks",

    // Engine specs
    multipleEngineOptions: "Multiple engine options",
    engineSpecCTA: "Show all engine options",
    allEngineOptions: "All engine options",
    closeEngineOptions: "Close engine options",
    specsUnavailable: "Not available",
    specsOptional: "Optional",
    specsIncluded: "Included",

    gradeSelectorSpecWheeldrive: "Wheel drive",
    gradeSelectorSpecTransmission: "Transmission",
    gradeSelectorSpecEnginePrice: "Engine price",
    gradeSelectorSpecEcoTagTitle: "CO2 Class",
    gradeSelectorSpecEcoBadgeTitle: "Etiquette",

    // Grade switcher
    keepGrade: "Keep",
    selectGrade: "Select",
    gradeSwitcherTitle: "All {model} grades",

    // Fueltypes
    hybrid: "Hybrid",
    electric: "Electric",
    plugin: "Plug-in Hybrid",
    fuelCell: "Fuel Cell",
    diesel: "Diesel",
    petrol: "Petrol",
    standard: "Standard",
    all: "All",

    // SR only a11y specific
    ariaToggleFuelInfo: "Toggle fuel info",
    ariaToggleCo2Info: "Toggle co2 info",
    ariaScrollPrevious: "Scroll Previous",
    ariaScrollNext: "Scroll Next",

    // Grade compare
    gradeCompareTitle: "Compare up to {num} grades",
    gradeCompareSelectGrade: "Select grade",

    // Exchange rate
    exchangeRate: "Fixed conversion rate: {primaryPrice} = {secondaryPrice}",

    // Filters
    gradeSelectorFilterAllGradesButton: "All grades",
    gradeSelectorFilterShowMore: "More filters",
    gradeSelectorFilterShowLess: "Fewer filters",
    gradeSelectorFilterSeeResultsDisabled: "See results",
    gradeSelectorFilterSeeResults: "See {amount} results",
    gradeSelectorFilterSeeResult: "See 1 result",
    gradeSelectorFilterEngineButton: "Engine",
    gradeSelectorFilterWheeldriveButton: "Wheel drive",
    gradeSelectorFilterTransmissionButton: "Transmission",
    gradeSelectorFilterColourButton: "Colour",
    gradeSelectorAllFiltersButton: "All filters",
    gradeSelectorActiveFilters: "{amount} active filters",
    gradeSelectorActiveFilter: "1 active filter",
    gradeSelectorRemoveFilters: "Remove all",

    gradeSelectorEngineOptions: "Engine options",
    gradeSelectorEngineInfo: "Engine info",
    gradeSelectorExteriorFeatures: "Exterior features",
    gradeSelectorInteriorFeatures: "Interior features",

    // Legend
    gradeSelectorLegendTitle: "Legend",
    gradeSelectorLegendStandard: "Standard",
    gradeSelectorLegendOptional: "Optional",
    gradeSelectorLegendNotApplicable: "Not applicable",

    //Retailer specific
    gradeSelectorRetailerChooseYour: "Choose your {model} grade",
    gradeSelectorRetailerGradeHighlightsCtaButton: "All highlights",
    gradeSelectorRetailerGradeSpecsCtaButton: "All specifications",
    gradeSelectorRetailerContinueCtaButton: "Continue configuration",
    gradeSelectorRetailerEngineCtaButton: "Engine",
    gradeSelectorRetailerBackToModelSelection: "Back to model selection",
};

export type GradeSelectorLabelType = keyof typeof gradeSelectorLabels;
type GradeSelectorLabelParamType = Partial<
    Record<"model" | "num" | "primaryPrice" | "secondaryPrice" | "amount", string>
>;

type GradeSelectorLabelStateType = {
    commonSettings: CommonSettingsType;
    gradeSelectorSettings: GradeSelectorSettingsType;
};

export const useGradeSelectorLabel = getUseLabel(
    (state: GradeSelectorStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const useGradeSelectorOptionalLabel = getUseOptionalLabel(
    (state: GradeSelectorStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseOptionalLabelType<GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const getGradeSelectorLabel = getLabelFn(
    (state: GradeSelectorLabelStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetLabelType<GradeSelectorLabelStateType, GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const getGradeSelectorOptionalLabel = getOptionalLabelFn(
    (state: GradeSelectorLabelStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetOptionalLabelType<GradeSelectorLabelStateType, GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const GradeSelectorLabel = getLabelComponent(useGradeSelectorLabel) as LabelComponentType<
    GradeSelectorLabelType,
    GradeSelectorLabelParamType
>;

export const GradeSelectorMemoLabel = getMemoLabelComponent(useGradeSelectorLabel) as LabelComponentType<
    GradeSelectorLabelType,
    GradeSelectorLabelParamType
>;
