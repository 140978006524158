import React, { PropsWithChildren, memo, useCallback, useEffect, useMemo, useRef } from "react";

import clsx from "clsx";
import styles from "./Modal.module.scss";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import { IconVariantTypes } from "../atoms/Icon.types";

export type ModalProps = PropsWithChildren<{
    variant?:
        | "default"
        | "debug"
        | "fullscreen"
        | "fullscreen-spin"
        | "calculator"
        | "large"
        | "compare"
        | "filter"
        | "bottom";
    onRequestClose?: () => void;
    // Adds a title CTA on the top left of the modal that has an optional icon and a custom onClick function.
    headerAction?: { label: string; onClick: () => void; icon?: IconVariantTypes };
    topLayer?: boolean;
}> &
    React.HTMLProps<HTMLDialogElement>;

const Modal: React.FC<ModalProps> = ({
    open,
    variant = "default",
    children,
    onRequestClose,
    headerAction,
    topLayer = true,
    className,
    ...rest
}) => {
    const ref = useRef<HTMLDialogElement | null>(null);

    const dialogClasses = useMemo(() => {
        return clsx(
            styles.modal,
            styles[variant],
            !open && styles.closing,
            !topLayer && styles["out-of-toplayer"],
            className,
        );
    }, [open, variant, topLayer]);

    const onAnimationEnd = useCallback(() => {
        if (!open) {
            ref.current?.close();
            if (!topLayer) {
                document.body?.classList.remove("or-apheleia-modal-open");
            }
        }
    }, [open, topLayer]);

    const onCancel = useCallback(
        (event: React.SyntheticEvent<HTMLDialogElement, Event>) => {
            event.preventDefault();
            onRequestClose?.();
        },
        [onRequestClose],
    );

    useEffect(() => {
        if (open) {
            if (topLayer) {
                try {
                    ref.current?.showModal();
                } catch (error) {
                    // for retailer support (dev modals only, will be improved in DR-625)
                    console.error(error);
                }
            } else {
                ref.current?.show();
                document.body?.classList.add("or-apheleia-modal-open");
            }
        }
    }, [open, topLayer]);

    return (
        <>
            <dialog ref={ref} {...rest} onCancel={onCancel} onAnimationEnd={onAnimationEnd} className={dialogClasses}>
                <div>
                    <div className={styles.header}>
                        {headerAction && (
                            <Button className={styles["header-action"]} onClick={headerAction.onClick} variant="clean">
                                {headerAction.icon && (
                                    <Icon className={styles.icon} sizing="sm" variant={headerAction.icon} />
                                )}
                                {headerAction.label}
                            </Button>
                        )}

                        <Button
                            variant="btn-default"
                            circle
                            onClick={() => {
                                onRequestClose?.();
                            }}
                            aria-label="close"
                            className={styles.close}
                        >
                            <Icon variant="close-sm" sizing="xxs" aria-hidden="true" />
                        </Button>
                    </div>
                    {children}
                </div>
            </dialog>
            {!topLayer && <div className={styles.backdrop} />}
        </>
    );
};

export default memo(Modal);
