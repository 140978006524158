import React, { FC } from "react";
import clsx from "clsx";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import { GradeSelectorLabel } from "../../../../utils/labels";
import Button from "../../../../../shared-components/css-modules/toyota/atoms/Button";
import Icon from "../../../../../shared-components/css-modules/toyota/atoms/Icon";
import a11y from "../../../../../shared-components/css-modules/toyota/utils/Accessibility.module.scss";
import styles from "./Slider.module.scss";

type SliderButtonType = {
    amount: number;
    navigate: (direction?: 1 | -1) => () => void;
    nextButtonDisabled: boolean;
    prevButtonDisabled: boolean;
};

const SliderButtons: FC<SliderButtonType> = (props) => {
    const { amount, navigate, nextButtonDisabled, prevButtonDisabled } = props;
    const isRtl = useGradeSelectorSelector((state) => state.commonSettings.culture.isRTL);

    return (
        <div className={clsx(styles.buttons, amount < 3 && styles.hidden, amount < 2 && styles["force-hide"])}>
            <Button
                variant="btn-arrow"
                circle
                onClick={navigate(isRtl ? 1 : -1)}
                disabled={isRtl ? nextButtonDisabled : prevButtonDisabled}
            >
                <Icon variant="chevron-left" sizing="md" aria-hidden="true" />
                <span className={a11y["sr-only"]}>
                    <GradeSelectorLabel label="ariaScrollPrevious" />
                </span>
            </Button>
            <Button
                variant="btn-arrow"
                circle
                onClick={navigate(isRtl ? -1 : 1)}
                disabled={isRtl ? prevButtonDisabled : nextButtonDisabled}
            >
                <Icon variant="chevron-right" sizing="md" aria-hidden="true" />
                <span className={a11y["sr-only"]}>
                    <GradeSelectorLabel label="ariaScrollNext" />
                </span>
            </Button>
        </div>
    );
};

export default SliderButtons;
