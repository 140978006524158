import { useEffect, useState } from "react";
import * as DOMUtils from "history/DOMUtils";

export const useBodyScrollbarWidth = (): number => {
    const [scrollbarWidth, setScrollbarWidth] = useState(0);

    useEffect(() => {
        if (DOMUtils.canUseDOM) {
            // Create a dummy element with a scrollbar
            setScrollbarWidth(window.innerWidth - document.body.clientWidth);
        }
    }, []);

    return scrollbarWidth;
};
