import { useMemo } from "react";
import { EcoTagType } from "../../../../common-deprecated/types/CommonTypes";
import { useGradeSelectorSelector } from "../redux/hooks";
import { selectEngines, selectModel } from "../redux/slices/GradeSelectorModelSlice";
import { getEcoTagType } from "./gradeUtils";
import { GradeBodyTypeType } from "./types/Model";
import { applyFilters, hasActiveFilters as checkActiveFilters } from "./filterUtils";

export const useGradeSelectorFilters = (): (EcoTagType | "standard")[] => {
    const engines = useGradeSelectorSelector(selectEngines);

    return [
        ...new Set(
            engines.map((engine) => {
                return getEcoTagType(engine) ?? "standard";
            }),
        ),
    ];
};

export const useFilteredGradeBodyTypes = (): GradeBodyTypeType[] => {
    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);

    const hasActiveFilters = checkActiveFilters(activeFilters);

    const { gradeBodyTypes } = useGradeSelectorSelector(selectModel);

    return useMemo(() => {
        if (!hasActiveFilters) {
            return gradeBodyTypes;
        }

        return applyFilters(activeFilters, gradeBodyTypes);
    }, [activeFilters, gradeBodyTypes, hasActiveFilters]);
};
