import { Dispatch, ReducersMapObject } from "redux";
import { CommonActionsType, CommonReducerType } from "../../../../common-deprecated/redux/commonStore";
import UtilReducer from "../../../../common-deprecated/redux/reducers/UtilReducer";
import CommonSettingsReducer from "../../../../common-deprecated/redux/reducers/CommonSettingsReducer";
import ModalReducer from "../../../../common-deprecated/redux/reducers/ModalReducer";
import { getInitToolkitStore } from "../../../../common-deprecated/redux/storeHelpers";
import GradeSelectorSettingsReducer, {
    GradeSelectorSettingsActionTypes,
    GradeSelectorSettingsType,
} from "./reducers/GradeSelectorSettingsReducer";
import { gradeSelectorFiltersSlice, GradeSelectorFiltersType } from "./slices/GradeSelectorFiltersSlice";
import { gradeSelectorModelSlice, GradeSelectorModelType } from "./slices/GradeSelectorModelSlice";
import { gradeSelectorFinanceSlice, GradeSelectorFinanceType } from "./slices/GradeSelectorFinanceSlice";
import { GradeSelectorCompareType, gradeSelectorCompareSlice } from "./slices/GradeSelectorCompareSlice";

export type GradeSelectorStateType = CommonReducerType & {
    gradeSelectorSettings: GradeSelectorSettingsType;
    [gradeSelectorFiltersSlice.name]: GradeSelectorFiltersType;
    [gradeSelectorModelSlice.name]: GradeSelectorModelType;
    [gradeSelectorFinanceSlice.name]: GradeSelectorFinanceType;
    [gradeSelectorCompareSlice.name]?: GradeSelectorCompareType;
};

export type GradeSelectorActionType =
    | CommonActionsType
    | GradeSelectorSettingsActionTypes
    | ReturnType<
          | typeof gradeSelectorModelSlice.actions.init
          | typeof gradeSelectorModelSlice.actions.setColour
          | typeof gradeSelectorModelSlice.actions.setSelectedMotorization
          | typeof gradeSelectorFinanceSlice.actions.initFinance
          | typeof gradeSelectorFinanceSlice.actions.setFinanceState
          | typeof gradeSelectorCompareSlice.actions.init
          | typeof gradeSelectorCompareSlice.actions.setSelectedGrade
          | typeof gradeSelectorCompareSlice.actions.setFilter
          | typeof gradeSelectorFiltersSlice.actions.addFilterValue
          | typeof gradeSelectorFiltersSlice.actions.addColourFilterValue
          | typeof gradeSelectorFiltersSlice.actions.setFilterCategory
          | typeof gradeSelectorFiltersSlice.actions.clearFilterValue
          | typeof gradeSelectorFiltersSlice.actions.clearFilters
      >;

export type GradeSelectorDispatchType = Dispatch<GradeSelectorActionType>;

export const gradeSelectorReducers: ReducersMapObject<GradeSelectorStateType, any> = {
    utils: UtilReducer,
    commonSettings: CommonSettingsReducer,
    modal: ModalReducer,
    gradeSelectorSettings: GradeSelectorSettingsReducer,
    [gradeSelectorFiltersSlice.name]: gradeSelectorFiltersSlice.reducer,
    [gradeSelectorModelSlice.name]: gradeSelectorModelSlice.reducer,
    [gradeSelectorFinanceSlice.name]: gradeSelectorFinanceSlice.reducer,
};

export const gradeSelectorServerStore = getInitToolkitStore(gradeSelectorReducers, []);

export const gradeSelectorClientStore = getInitToolkitStore(gradeSelectorReducers, []);
