import { ModalMapType } from "../../../../../../common-deprecated/types/CommonTypes";
import {
    GradeSelectorModalIdsType,
    MODAL_DEBUG_FINANCE,
    MODAL_GRADE_SELECTOR_COMPARE,
    MODAL_MOBILE_GRADE_SELECTOR_FILTER,
} from "../../../utils/modalConstants";
import FinanceModal from "./debug-modals/FinanceModal";
import GradeSelectorCompare from "../selector/actions/compare/GradeSelectorCompare";
import { aemToyotaDebugModalMap } from "../../../../../../common-deprecated/components/modals/aem/modals";
import MobileFilterModal from "./filters/MobileFilterModal";
import ErrorModal from "../../../../../../common-deprecated/features/apheleia/components/debug-modals/ErrorModal";
import { MODAL_ERRORS } from "../../../../../../common-deprecated/utils/modalConstants";

export const aemGradeSelectorToyotaModalMap: ModalMapType<GradeSelectorModalIdsType> = {
    ...aemToyotaDebugModalMap,
    [MODAL_DEBUG_FINANCE]: FinanceModal,
    [MODAL_GRADE_SELECTOR_COMPARE]: GradeSelectorCompare,
    [MODAL_MOBILE_GRADE_SELECTOR_FILTER]: MobileFilterModal,
    [MODAL_ERRORS]: ErrorModal,
};
