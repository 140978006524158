import React from "react";
import { ModalErrorsType } from "../../../../utils/modalConstants";
import { ModalViewType } from "../../../../types/CommonTypes";
import Modal from "../../../../../new-cars/apheleia/shared-components/css-modules/toyota/organisms/Modal";
import styles from "./DebugModals.module.scss";
import Text from "../../../../../new-cars/apheleia/shared-components/css-modules/toyota/atoms/Text";

const ErrorModal = (props: ModalViewType<ModalErrorsType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { errors, componentName } = modalSettings;

    return (
        <Modal open={show} onRequestClose={close}>
            <div className={styles["debug-modal"]}>
                <Text tag="h2" variant="apheleia-h2" spacing="spacing-3">
                    Errors - {componentName}
                </Text>
                <div>
                    <ul>
                        {errors.map((error) => (
                            <li key={`${error.title} ${error.id as string}`}>
                                <strong>{error.title}</strong>
                                <hr />
                                {Array.isArray(error.message) ? (
                                    <ul>
                                        {error.message.map((errorMessage) => (
                                            <li key={`${errorMessage} ${error.id as string}`}>{errorMessage}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    error.message
                                )}
                                <br />
                                <em>{error.innerMessage}</em>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

export default ErrorModal;
