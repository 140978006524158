import {
    MODAL_RETAILER_DEBUG,
    RetailerDebugModalType,
} from "../../../../common-deprecated/features/retailer/components/modals/constants";
import { MODAL_ERRORS, ModalErrorsType } from "../../../../common-deprecated/utils/modalConstants";

export const MODAL_DEBUG_FINANCE: "debugFinance" = "debugFinance";
export const MODAL_GRADE_SELECTOR_COMPARE: "gradeSelectorCompare" = "gradeSelectorCompare";
export const MODAL_MOBILE_GRADE_SELECTOR_FILTER: "gradeSelectorFilter" = "gradeSelectorFilter";

export type GradeSelectorModalIdsType =
    | typeof MODAL_DEBUG_FINANCE
    | typeof MODAL_RETAILER_DEBUG
    | typeof MODAL_GRADE_SELECTOR_COMPARE
    | typeof MODAL_MOBILE_GRADE_SELECTOR_FILTER
    | typeof MODAL_ERRORS;

export type DebugFinanceModalType = {
    type: typeof MODAL_DEBUG_FINANCE;
};

export type GradeSelectorCompareModalType = {
    type: typeof MODAL_GRADE_SELECTOR_COMPARE;
    gradeId: string;
    submodelId?: string;
    colourId?: string;
    motorization?: {
        engineId: string;
        transmissionId: string;
        wheeldriveId: string;
    };
};

export type GradeSelectorFilterModalType = {
    type: typeof MODAL_MOBILE_GRADE_SELECTOR_FILTER;
};

export type GradeSelectorModalsType =
    | DebugFinanceModalType
    | GradeSelectorCompareModalType
    | RetailerDebugModalType
    | GradeSelectorFilterModalType
    | ModalErrorsType;
