import React, { useState, useRef, PropsWithChildren, MouseEvent, ReactNode, useEffect } from "react";
import clsx from "clsx";
import Text from "../atoms/Text";
import Icon from "../atoms/Icon";
import styles from "./Collapse.module.scss";

export type Props = {
    summary: ReactNode;
    onChange?: (isOpen: boolean) => void;
} & Omit<React.HTMLProps<HTMLDetailsElement>, "summary">;

const Collapse: React.FC<PropsWithChildren<Props>> = (props) => {
    const { summary, children, open, className, ...rest } = props;
    const [internalIsOpen, setInternalIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleOpen = (event: MouseEvent<HTMLDetailsElement>): void => {
        event.preventDefault();
        if (!props.disabled) {
            setInternalIsOpen(!internalIsOpen);
            props.onChange?.(!internalIsOpen);
        }
    };

    // When the passed open property changes, set the internalIsOpen to the passed property
    useEffect(() => {
        setInternalIsOpen(!!open);
    }, [open]);

    return (
        <details open={internalIsOpen} {...rest} className={clsx(styles.details, className)}>
            <summary onClick={toggleOpen}>
                <Text tag="span" variant="apheleia-h5">
                    {summary}
                </Text>
                <Icon
                    // Can't use .open as classname as this breaks nested Collapse components.
                    style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
                    variant="chevron-down"
                    sizing="md"
                    className={styles.icon}
                />
            </summary>
            <div className={clsx(styles.content)} ref={contentRef}>
                {children}
            </div>
        </details>
    );
};

export default Collapse;
