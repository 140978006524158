import { RefObject, useCallback, useRef, useState } from "react";

export function useDrag<TElement extends HTMLElement>(): {
    isDragging: boolean;
    handleMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
    handleMouseUp: () => void;
    handleMouseMove: (event: React.MouseEvent<HTMLElement>) => void;
    ref: RefObject<TElement>;
} {
    const ref = useRef<TElement>(null);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);

    const handleMouseDown = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setIsDragging(true);
        setStartX(event.clientX);
    }, []);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleMouseMove = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (!isDragging || !ref.current) return;

            const deltaX = event.clientX - startX;
            ref.current.scrollLeft -= deltaX;
            setStartX(event.clientX);
        },
        [isDragging, startX],
    );

    return { isDragging, handleMouseDown, handleMouseUp, handleMouseMove, ref };
}
